import React, { useEffect, useRef, useState } from 'react';
import { Search } from 'semantic-ui-react';
import { fetchProfessorAndCourseSearch } from '../../util/FetchSearchData';
import SearchRow from '../tables/table-rows/SearchRow';

function rowRenderer(searchResult) {
    return (
        <SearchRow
            content={searchResult}
            label={searchResult.search_result_type}
        />
    );
}

const SearchBar = () => {
    const [input, setInput] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const abortControllerRef = useRef(null);

    // Debounce and fetch search data
    useEffect(() => {
        const fetchSearchData = async (query) => {
            try {
                // Cancel previous request if it exists
                if (abortControllerRef.current) {
                    abortControllerRef.current.abort();
                }

                // Create a new AbortController for the current request
                const abortController = new AbortController();
                abortControllerRef.current = abortController;

                const response = await fetchProfessorAndCourseSearch(
                    query,
                    abortController.signal,
                );
                const result = await response.json();

                // Only update state if the request wasn't aborted
                if (!abortController.signal.aborted) {
                    setSearchResults(result);
                    setSearchLoading(false);
                }
            } catch (error) {
                if (error.name !== 'AbortError') {
                    console.error('Search error:', error);
                    setSearchLoading(false);
                }
            }
        };

        // Only trigger loading and search when there's input
        if (input.trim()) {
            setSearchLoading(true);
            const debounceTimer = setTimeout(() => {
                fetchSearchData(input);
            }, 1000); // 1000ms debounce delay

            return () => {
                clearTimeout(debounceTimer);
                if (abortControllerRef.current) {
                    abortControllerRef.current.abort(); // Cancel ongoing request
                }
            };
        } else {
            setSearchResults([]);
            setSearchLoading(false);
        }
    }, [input]);

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    return (
        <Search
            fluid
            className="width-70 search-barr font-size-20"
            input={{ fluid: true }}
            value={input}
            placeholder="Search for a Class or Professor"
            results={searchResults}
            onSearchChange={handleInputChange}
            loading={searchLoading}
            resultRenderer={rowRenderer}
        />
    );
};

export default SearchBar;

import getCookie from './FetchAuthentication';
const api_url = process.env.REACT_APP_GATEWAY_URL;

// Fetch all users
export const fetchUsers = async () => {
    return fetch(`${api_url}api/authentication/users`, {
        method: 'GET',
        headers: {
            'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
        credentials: 'include',
    });
};

// Delete a user
export const deleteUser = async (userId) => {
    return fetch(`${api_url}api/authentication/users/${userId}`, {
        method: 'DELETE',
        headers: {
            'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
        credentials: 'include',
    });
};

// Register a new user (admin action)
export const registerUser = async (userData) => {
    return fetch(`${api_url}api/authentication/register`, {
        method: 'POST',
        body: JSON.stringify(userData),
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
        credentials: 'include',
    });
};

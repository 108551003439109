import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import LoaderAnimation from '../../common/LoaderAnimation';
import ReviewCard from './ReviewCard';

const MostRecentReviews = ({ isLoading, recentReviews }) => {
    if (isLoading) {
        return (
            <Container style={{ paddingBottom: '5em' }}>
                <LoaderAnimation />
            </Container>
        );
    }

    if (recentReviews.length === 0) {
        return (
            <Container style={{ paddingBottom: '5em' }}>
                <h2>No reviews found</h2>
            </Container>
        );
    }

    return (
        <Container style={{ paddingBottom: '5em' }}>
            <Grid columns={2} stackable className="margin-0">
                {recentReviews.map((review, index) => {
                    return (
                        <Grid.Column style={{ height: '100%' }}>
                            <ReviewCard
                                key={index}
                                review={review}
                                showCourse={true}
                                showProfessor={true}
                            />
                        </Grid.Column>
                    );
                })}
            </Grid>
        </Container>
    );
};

export default MostRecentReviews;

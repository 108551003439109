import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Button,
    Container,
    Dropdown,
    Grid,
    Header,
    Label,
} from 'semantic-ui-react';
import CourseCard from '../components/cards/course-cards/CourseCard';
import Review from '../components/cards/review-cards/ReviewCard'; // Assuming ReviewCard is the correct component name
import Footer from '../components/common/Footer';
import LoaderAnimation from '../components/common/LoaderAnimation';
import Navbar from '../components/common/Navbar';
import { fetchAllReviewsForCourse } from '../util/FetchReviewData'; // Assuming fetchNumberOfReviewsForCourse is not needed anymore

const sortOptions = [
    { key: 'newest', text: 'Newest', value: 'newest' },
    { key: 'oldest', text: 'Oldest', value: 'oldest' },
    { key: 'most_agreed', text: 'Most Agreed', value: 'most_agreed' },
    { key: 'most_disagreed', text: 'Most Disagreed', value: 'most_disagreed' },
    { key: 'most_funny', text: 'Most Funny', value: 'most_funny' },
];

const CoursePage = () => {
    const { courseId } = useParams();

    const [numberOfReviews, setNumberOfReviews] = useState(0); // State to store the total count

    const [pageNumber, setPageNumber] = useState(1);

    const [reviewsLoading, setReviewsLoading] = useState(false);
    const [reviews, setReviews] = useState([]);
    const [hasMoreReviews, setHasMoreReviews] = useState(true);

    const [sortKey, setSortKey] = useState(null); // Use null for initial/cleared state
    const [professors, setProfessors] = useState([]);
    const [professorFilter, setProfessorFilter] = useState(null); // Use null for initial/cleared state

    // Refs are not strictly necessary for this logic after fixing useEffect
    // const reviewsContainerRef = useRef(null);
    // const prevReviewsLengthRef = useRef(0);

    const loadMoreReviews = () => {
        // Increment page number to trigger loading more reviews via useEffect
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
    };

    const handleSortChange = (event, data) => {
        setSortKey(data.value === '' ? null : data.value); // Set to null if cleared
        // Reset page and reviews when changing sort
        setPageNumber(1);
        setReviews([]);
        setHasMoreReviews(true); // Assume there might be reviews with the new sort
    };

    const handleProfessorChange = (event, data) => {
        setProfessorFilter(data.value === '' ? null : data.value); // Set to null if cleared
        // Reset page and reviews when changing filter
        setPageNumber(1);
        setReviews([]);
        setHasMoreReviews(true); // Assume there might be reviews with the new filter
    };

    // Build options for the professor dropdown including a default "All Professors" option
    const professorOptions = [
        { key: 'all', text: 'All Professors', value: '' }, // Use empty string for "All" to match Dropdown clearable behavior
        ...professors.map((prof) => ({
            key: prof.professor_id,
            // Use a consistent display format, check prof structure if this causes issues
            text: `${prof.first_name} ${prof.last_name}${prof.uni ? ` [${prof.uni}]` : ''}`,
            value: prof.professor_id,
        })),
    ];

    // Fetch reviews effect - CORRECTED
    useEffect(() => {
        const fetchReviews = async () => {
            try {
                setReviewsLoading(true);
                const response = await fetchAllReviewsForCourse(
                    courseId,
                    pageNumber,
                    sortKey,
                    professorFilter, // Pass null if no filter selected
                );

                // Ensure response is ok before parsing JSON
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const result = await response.json(); // result = { reviews: [...], number_of_reviews: N }

                // Set the total number of reviews based on the API response
                setNumberOfReviews(result['number_of_reviews']); // FIX 2: Set the state

                if (pageNumber === 1) {
                    // If first page, replace reviews
                    setReviews(result['reviews'] || []); // Use empty array as fallback
                } else {
                    // FIX 1: Correctly append the reviews array from the result
                    setReviews((prevReviews) => [
                        ...prevReviews,
                        ...(result['reviews'] || []), // Spread the inner array, fallback to empty
                    ]);
                }

                // Calculate if there are more reviews to load using the count from the result
                // Assuming 5 items per page based on backend LIMIT
                const itemsPerPage = 5;
                const totalPages = Math.ceil(
                    result['number_of_reviews'] / itemsPerPage,
                );
                setHasMoreReviews(pageNumber < totalPages);
            } catch (error) {
                console.error('Failed to fetch course reviews:', error);
                // Optionally set an error state here for user feedback
                setReviews([]); // Clear reviews on error
                setHasMoreReviews(false); // Stop trying to load more on error
                setNumberOfReviews(0); // Reset count on error
            } finally {
                setReviewsLoading(false);
            }
        };

        fetchReviews();
        // FIX 3: Correct dependency array - remove numberOfReviews
    }, [courseId, pageNumber, sortKey, professorFilter]);

    return (
        <div style={{ width: '100%' }}>
            <Navbar />
            <br />
            <br />
            <Container>
                <CourseCard
                    course_id={courseId}
                    professors={professors} // Pass professors state down
                    setProfessors={setProfessors} // Pass setter down
                />
            </Container>
            <br />
            <br />
            {/* Show loader only when loading initial reviews */}
            {reviewsLoading && pageNumber === 1 ? (
                <Container>
                    <LoaderAnimation />
                </Container>
            ) : (
                <>
                    <Container>
                        <Header
                            as="h2"
                            style={{ margin: '0 auto', textAlign: 'center' }}
                        >
                            {/* Display total number of reviews found */}
                            {numberOfReviews} Review
                            {numberOfReviews !== 1 ? 's' : ''} Found
                        </Header>
                        <br />
                        <Grid
                            container // Use container prop for centering/padding
                            stackable
                            columns={2}
                            style={{ marginBottom: '1rem' }}
                        >
                            <Grid.Row>
                                <Grid.Column
                                // Remove inline style, rely on SUI defaults or CSS classes
                                >
                                    <Dropdown
                                        placeholder="Sort Reviews" // Use placeholder
                                        trigger={
                                            // Optional: Keep trigger logic if you prefer the Label style
                                            sortKey ? (
                                                <Label color="grey">
                                                    {' '}
                                                    {/* Use grey, not gray */}
                                                    {
                                                        sortOptions.find(
                                                            (o) =>
                                                                o.value ===
                                                                sortKey,
                                                        )?.text
                                                    }
                                                </Label>
                                            ) : undefined // Trigger is null/undefined if not active
                                        }
                                        selection
                                        clearable
                                        options={sortOptions}
                                        value={sortKey} // Bind directly to state (null or value)
                                        onChange={handleSortChange}
                                        fluid
                                    />
                                </Grid.Column>
                                <Grid.Column
                                // Remove inline style
                                >
                                    <Dropdown
                                        placeholder="Filter by Professor" // Use placeholder
                                        trigger={
                                            // Optional: Keep trigger logic
                                            professorFilter ? (
                                                <Label color="grey">
                                                    {
                                                        professorOptions.find(
                                                            (o) =>
                                                                o.value ===
                                                                professorFilter,
                                                        )?.text
                                                    }
                                                </Label>
                                            ) : undefined
                                        }
                                        selection
                                        clearable
                                        options={professorOptions}
                                        value={
                                            professorFilter === null
                                                ? ''
                                                : professorFilter
                                        } // Map null to empty string for Dropdown value if using placeholder + clearable
                                        onChange={handleProfessorChange}
                                        fluid
                                        search // Good for long lists of professors
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>

                    {/* Only render review list container if there are reviews or loading subsequent pages */}
                    {(reviews.length > 0 ||
                        (reviewsLoading && pageNumber > 1)) && (
                        <Container>
                            {/* Wrap the review list in a Grid */}
                            <Grid stackable>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        {reviews.map((review, index) => (
                                            // Use review.review_id for key if available and unique
                                            <React.Fragment
                                                key={review.review_id || index}
                                            >
                                                <Review
                                                    review={review}
                                                    applyDescriptionStyling={
                                                        false
                                                    }
                                                    // Show professor info on course page reviews
                                                    showProfessor={true}
                                                    // Don't need to show course again if it's the course page
                                                    showCourse={false}
                                                />
                                                <br />
                                            </React.Fragment>
                                        ))}

                                        {/* Load More Button */}
                                        {hasMoreReviews && (
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                    margin: '2rem 0',
                                                }}
                                            >
                                                <Button
                                                    className="load-more-button"
                                                    onClick={loadMoreReviews}
                                                    // Show loading indicator on button only when loading next page
                                                    loading={
                                                        reviewsLoading &&
                                                        pageNumber > 1
                                                    }
                                                    disabled={reviewsLoading} // Disable while any loading is happening
                                                >
                                                    Load More
                                                </Button>
                                            </div>
                                        )}

                                        {/* No more reviews message - show only if not loading and no more pages */}
                                        {!hasMoreReviews &&
                                            !reviewsLoading &&
                                            reviews.length > 0 && (
                                                <div
                                                    style={{
                                                        textAlign: 'center',
                                                        margin: '2rem 0',
                                                        color: 'grey', // Subtle color
                                                    }}
                                                >
                                                    <p>
                                                        No more reviews to load.
                                                    </p>
                                                </div>
                                            )}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Container>
                    )}

                    {/* Message if no reviews are found after initial load */}
                    {!reviewsLoading &&
                        reviews.length === 0 &&
                        pageNumber === 1 && (
                            <Container
                                style={{
                                    textAlign: 'center',
                                    margin: '2rem 0',
                                    color: 'grey',
                                }}
                            >
                                <p>
                                    No reviews found for this course{' '}
                                    {professorFilter
                                        ? 'and selected professor'
                                        : ''}
                                    .
                                </p>
                            </Container>
                        )}
                </>
            )}
            <br />
            <br />
            <Footer />
        </div>
    );
};

export default CoursePage;

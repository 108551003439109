import React from 'react';
import { Container } from 'semantic-ui-react';
import styled from 'styled-components';
import Footer from '../components/common/Footer';
import Navbar from '../components/common/Navbar';

const ContentWrapper = styled.div`
    padding-top: 4em;
    padding-left: 25%;
    padding-right: 25%;
    flex: 1;

    @media (max-width: 768px) {
        padding-left: 5%;
        padding-right: 5%;
    }
`;

const FlexContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const AboutUsPage = () => (
    <>
        <Navbar />
        <FlexContainer style={{ width: '100vw', paddingBottom: '5em' }}>
            <ContentWrapper>
                <h1>About Us</h1>
                <p style={{ fontSize: '1.2em', fontWeight: '400' }}>
                    CULPA (Columbia Underground Listing of Professor Ability),
                    founded in 1997, is a public platform for students at
                    Columbia University to anonymously review courses and
                    professors. Our mission is to provide utility to students by
                    facilitating honest, unfiltered opinions and discussions on
                    the academic experience. The platform is moderated by
                    current students to consistently deliver up-to-date
                    information on courses and professors. As of November 15,
                    2022, CULPA is a service of the Spectator Publishing Company
                    operated by Spectator's Product and Engineering teams. CULPA
                    began distributing course guides in print in 1932, with
                    Professor Guides handed out to students on campus. In 1997,
                    CULPA launched its first website, CULPA Online. CULPA joined
                    Spectator Publishing Company on November 15, 2022.
                </p>
                <p style={{ fontSize: '1.2em', fontWeight: '400' }}>
                    Spectator Publishing Company is a non-profit educational
                    organization chartered in 1877 and independent from Columbia
                    University. It publishes Columbia Daily Spectator, The Eye,
                    and Bwog.
                </p>
            </ContentWrapper>
        </FlexContainer>
        <br />
        <br />
        <Footer />
    </>
);

export default AboutUsPage;

import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

// Import our custom fetch utilities
import { RateLimitProvider, useRateLimit } from './util/api';

// Page imports
import AboutUsPage from './pages/AboutUsPage';
import AdminLoginPage from './pages/AdminLoginPage';
import AdminPage from './pages/AdminPage';
import AnnouncementsPage from './pages/AnnouncementsPage';
import CoursePage from './pages/CoursePage';
import FaqsPage from './pages/FaqsPage';
import HomePage from './pages/HomePage';
import JoinTheTeamPage from './pages/JoinTheTeamPage';
import NewReviewPage from './pages/NewReviewPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import ProfessorPage from './pages/ProfessorPage';
import TermsOfUsePage from './pages/TermsOfUsePage';
import { CsrfTokenProvider } from './util/CsrfTokenProvider';

// Rate limit page component
const RateLimitPage = () => {
    return (
        <div className="rate-limit-container">
            <h1>Rate Limit Exceeded</h1>
            <p>You've made too many requests in a short period of time.</p>
            <p>Please wait a moment before trying again.</p>
        </div>
    );
};

// Component that conditionally renders based on rate limit status
const RateLimitHandler = ({ children }) => {
    const { isRateLimited } = useRateLimit();

    if (isRateLimited) {
        return <RateLimitPage />;
    }

    return children;
};

function App() {
    return (
        <CsrfTokenProvider>
            <RateLimitProvider>
                <AppContent />
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </RateLimitProvider>
        </CsrfTokenProvider>
    );
}

// Separate component to access context inside the provider
function AppContent() {
    const rateLimitState = useRateLimit();

    // Make the rate limit state globally accessible for the fetch wrapper
    useEffect(() => {
        window.rateLimitState = rateLimitState;

        // Cleanup function
        return () => {
            delete window.rateLimitState;
        };
    }, [rateLimitState]);

    return (
        <RateLimitHandler>
            <Router>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/home" element={<HomePage />} />
                    <Route
                        path="/professor/:professorId"
                        element={<ProfessorPage />}
                    />
                    <Route path="/course/:courseId" element={<CoursePage />} />
                    <Route path="/login" element={<AdminLoginPage />} />
                    <Route path="/admin" element={<AdminPage />} />
                    <Route path="/review/new" element={<NewReviewPage />} />
                    <Route path="/about" element={<AboutUsPage />} />
                    <Route path="/join" element={<JoinTheTeamPage />} />
                    <Route path="/faqs" element={<FaqsPage />} />
                    <Route path="/privacy" element={<PrivacyPolicyPage />} />
                    <Route path="/terms" element={<TermsOfUsePage />} />
                    <Route
                        path="/announcements"
                        element={<AnnouncementsPage />}
                    />
                </Routes>
            </Router>
        </RateLimitHandler>
    );
}

export default App;

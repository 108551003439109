import getCookie from './FetchAuthentication';
import { fetchWithRateLimitCheck } from './api';

const api_url = process.env.REACT_APP_GATEWAY_URL;

// This file contains functions that fetch data from the backend related to professors
export function fetchPresignedUrl() {
    return fetchWithRateLimitCheck(`${api_url}api/syllabus/presigned_url`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
    });
}

export function uploadSyllabus(file, url) {
    return fetchWithRateLimitCheck(url, {
        method: 'PUT',
        body: file,
        headers: {
            'Content-Type': 'application/pdf',
        },
    });
}

export function confirmSyllabusUpload(file_name, course_professor_id) {
    return fetchWithRateLimitCheck(
        `${api_url}api/syllabus/confirm_submission`,
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                file_name: file_name,
                course_professor_id: course_professor_id,
            }),
            credentials: 'include',
        },
    );
}

export function fetchProfessorSyllabi(professor_id) {
    return fetchWithRateLimitCheck(
        `${api_url}api/syllabus/professor/${professor_id}`,
        {
            method: 'GET',
        },
    );
}

export function fetchCourseSyllabi(course_id) {
    return fetchWithRateLimitCheck(
        `${api_url}api/syllabus/course/${course_id}`,
        {
            method: 'GET',
        },
    );
}

export const fetchPendingSyllabi = async (page = 1) => {
    try {
        const response = await fetch(
            `${api_url}api/syllabus/pending?page=${page}`,
            {
                method: 'GET',
                credentials: 'include',
            },
        );

        return response;
    } catch (error) {
        console.error('Error fetching pending syllabi:', error);
        throw error;
    }
};

export function approveSyllabus(syllabus_id, new_status, csrfToken) {
    return fetchWithRateLimitCheck(`${api_url}api/syllabus/approve`, {
        method: 'POST',
        body: JSON.stringify({
            syllabus_id: syllabus_id,
            new_status: new_status,
        }),
        headers: {
            'X-CSRF-TOKEN': csrfToken,
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });
}

import React from 'react';
import {
    Placeholder,
    PlaceholderHeader,
    PlaceholderLine,
    PlaceholderParagraph,
    Segment,
} from 'semantic-ui-react';

const LoadingSegment = () => (
    <Segment>
        <Placeholder>
            <PlaceholderHeader image>
                <PlaceholderLine />
                <PlaceholderLine />
            </PlaceholderHeader>
            <PlaceholderParagraph>
                <PlaceholderLine />
                <PlaceholderLine />
                <PlaceholderLine />
                <PlaceholderLine />
            </PlaceholderParagraph>
        </Placeholder>
    </Segment>
);

export default LoadingSegment;

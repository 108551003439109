import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, Header, Image } from 'semantic-ui-react';
import Typewriter from 'typewriter-effect';
import MostRecentReviews from '../components/cards/review-cards/MostRecentReviews';
import MenuButton from '../components/common/buttons/MenuButton';
import Footer from '../components/common/Footer';
import MobileMenu from '../components/common/MobileMenu';
import SearchBar from '../components/common/SearchBar';
import CulpaIcon from '../icons/culpa.svg';
import ModernCulpaIcon from '../icons/modern-culpa.svg';
import BronzeNugget from '../icons/nuggets/bronze-nugget.svg';
import GoldNugget from '../icons/nuggets/gold-nugget.svg';
import SilverNugget from '../icons/nuggets/silver-nugget.svg';
import { fetchFrontPageData } from '../util/FetchFrontPageData';

const HomePage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [totalVotes, setTotalVotes] = useState(0);
    const [totalReviews, setTotalReviews] = useState(0);
    const [totalCourses, setTotalCourses] = useState(0);
    const [totalProfessors, setTotalProfessors] = useState(0);
    const [mostRecentReviews, setMostRecentReviews] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => setMenuOpen(!menuOpen);

    async function getFrontPageData() {
        try {
            setIsLoading(true);
            const response = await fetchFrontPageData();
            const data = await response.json();
            if (response.ok) {
                setMostRecentReviews(data.most_recent_reviews);
                setTotalVotes(data.total_values.total_votes);
                setTotalReviews(data.total_values.total_reviews);
                setTotalCourses(data.total_values.total_courses);
                setTotalProfessors(data.total_values.total_professors);
            }
        } catch (error) {
            console.error('Error fetching front page data:', error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getFrontPageData();
    }, []);

    return (
        <>
            <MobileMenu
                open={menuOpen}
                onClose={toggleMenu}
                userName={null}
                userNameLoading={false}
            />

            <div className="homepage-container">
                <div
                    className="modern-culpa-container"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Image src={ModernCulpaIcon} href="/home" />
                    <MenuButton onClick={toggleMenu} />
                </div>

                <div className="culpa-icon-text-container">
                    <Image
                        src={CulpaIcon}
                        size="large"
                        className="culpa-icon"
                    />
                    <div className="homepage-title">
                        Columbia's Anonymous Professor and Course Rating Site
                    </div>
                </div>
                <div className="search-bar-container">
                    <SearchBar />
                </div>
                <Button
                    className="orange-button"
                    content="WRITE A REVIEW"
                    size="large"
                    href="/review/new"
                />
            </div>

            <div className="nugget-explanation-section">
                <div className="nugget-explanation-container">
                    <span className="nugget-explanation-text">
                        Professors with more than 5 approved reviews can earn a
                        nugget based on their average rating: bronze(3.0+),
                        silver(3.5+) and gold(4.0+)
                    </span>
                    <Image
                        className="nugget-icon"
                        spaced="right"
                        src={BronzeNugget}
                    />
                    <Image
                        className="nugget-icon"
                        spaced="right"
                        src={SilverNugget}
                    />
                    <Image
                        className="nugget-icon"
                        spaced="right"
                        src={GoldNugget}
                    />
                </div>
            </div>

            <Container
                textAlign="center"
                className="recent-reviews-header-container"
            >
                <Header
                    as="h2"
                    style={{
                        color: '#4071CA',
                        fontSize: '2em',
                        fontWeight: '700',
                    }}
                >
                    Last Week's Most Popular Reviews
                </Header>
            </Container>
            <MostRecentReviews
                isLoading={isLoading}
                recentReviews={mostRecentReviews}
            />

            <div className="stats-container no-margin-left-right">
                <div className="typewriter-container">
                    <div className="consolas-font typewriter-text">
                        <Typewriter
                            options={{
                                strings: ['AS OF NOW, CULPA HAS'],
                                autoStart: true,
                                loop: true,
                                delay: 40,
                            }}
                        />
                    </div>
                </div>
                <Grid columns={2} className="stats-grid">
                    <Grid.Row className="stats-row">
                        <Grid.Column
                            width={10}
                            className="stats-numbers-column"
                        >
                            <span className="front-page-numbers stats-number">
                                {totalReviews.toLocaleString()}
                            </span>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <span className="front-page-labels">REVIEWS</span>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row className="stats-row">
                        <Grid.Column
                            width={10}
                            className="stats-numbers-column"
                        >
                            <span className="front-page-numbers stats-number">
                                {totalProfessors.toLocaleString()}
                            </span>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <span className="front-page-labels">
                                PROFESSORS
                            </span>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row className="stats-row">
                        <Grid.Column
                            width={10}
                            className="stats-numbers-column"
                        >
                            <span className="front-page-numbers stats-number">
                                {totalCourses.toLocaleString()}
                            </span>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <span className="front-page-labels">COURSES</span>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row className="stats-row">
                        <Grid.Column
                            width={10}
                            className="stats-numbers-column"
                        >
                            <span className="front-page-numbers stats-number">
                                {totalVotes.toLocaleString()}
                            </span>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <span className="front-page-labels">VOTES</span>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
            <Footer />
        </>
    );
};

export default HomePage;

import React from 'react';
import { Card } from 'semantic-ui-react';
import Quote from './Quote';

const QuoteCard = ({ quotes }) => {
    if (quotes.length === 0) {
        return <></>;
    }

    return (
        <Card
            fluid
            style={{
                borderRadius: '5px',
                backgroundColor: '#F2F2F2',
                fontWeight: '500',
                color: 'black',
                boxShadow: '0 0 0 0',
            }}
        >
            <Card.Content>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {quotes.map((quote, index) => (
                        <Quote key={index} quote={quote} />
                    ))}
                </div>
            </Card.Content>
        </Card>
    );
};

export default QuoteCard;

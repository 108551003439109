import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Image } from 'semantic-ui-react';

// Import custom SVG stars instead of Font Awesome icons
import filledStarUrl from '../icons/stars/filled_star.svg';
import halfFilledStarUrl from '../icons/stars/half_filled_star.svg';
import notFilledStarUrl from '../icons/stars/not_filled_star.svg';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const RatingDistribution = ({ mratings }) => {
    const ratings = [
        mratings.one,
        mratings.two,
        mratings.three,
        mratings.four,
        mratings.five,
    ];

    // Calculate average rating
    const totalReviews = ratings.reduce((a, b) => a + b, 0);
    const averageRating =
        totalReviews > 0
            ? Math.round(
                  (ratings.reduce(
                      (sum, count, index) => sum + count * (index + 1),
                      0,
                  ) /
                      totalReviews) *
                      100,
              ) / 100
            : 0;

    // Chart data configuration
    const chartData = {
        labels: [
            'Very Poor (1★)',
            'Poor (2★)',
            'Fair (3★)',
            'Great (4★)',
            'Excellent (5★)',
        ],
        datasets: [
            {
                data: ratings,
                backgroundColor: '#1d2c4d', // Changed to dark blue
                borderColor: '#1d2c4d', // Changed to match background
                borderWidth: 1,
                borderRadius: 8,
                borderSkipped: false,
            },
        ],
    };

    // Chart options with gridlines enabled
    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: { display: false },
            tooltip: { enabled: true },
        },
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    display: true,
                    color: '#E5E7EB',
                    lineWidth: 1,
                },
                ticks: {
                    color: '#6B7280',
                    font: {
                        family: "'Roboto Mono', 'Courier New', monospace", // Monospace font
                        weight: 600, // Increased font weight for modern look
                    },
                },
            },
            x: {
                grid: {
                    display: true,
                    color: '#E5E7EB',
                    lineWidth: 1,
                },
                ticks: {
                    color: '#6B7280',
                    font: {
                        family: "'Roboto Mono', 'Courier New', monospace", // Monospace font
                        weight: 600, // Increased font weight for modern look
                    },
                },
            },
        },
        font: {
            family: "'Roboto Mono', 'Courier New', monospace", // Global monospace font
            weight: 600, // Increased font weight for modern look
        },
    };

    // Star display logic using custom SVG images
    const renderStars = () => {
        const stars = [];
        const fullStars = Math.floor(averageRating);
        const hasHalfStar = averageRating % 1 !== 0;

        for (let i = 0; i < 5; i++) {
            if (i < fullStars) {
                stars.push(
                    <Image
                        key={i}
                        src={filledStarUrl}
                        className="w-6 h-6"
                        width="32px"
                        inline
                        alt="Filled star"
                        style={{
                            marginLeft: '0.25rem',
                            marginRight: '0.25rem',
                        }}
                    />,
                );
            } else if (i === fullStars && hasHalfStar) {
                stars.push(
                    <Image
                        key={i}
                        src={halfFilledStarUrl}
                        className="w-6 h-6"
                        width="32px"
                        inline
                        alt="Half-filled star"
                        style={{
                            marginLeft: '0.25rem',
                            marginRight: '0.25rem',
                        }}
                    />,
                );
            } else {
                stars.push(
                    <Image
                        key={i}
                        src={notFilledStarUrl}
                        className="w-6 h-6"
                        width="32px"
                        inline
                        alt="Empty star"
                        style={{
                            marginLeft: '0.25rem',
                            marginRight: '0.25rem',
                        }}
                    />,
                );
            }
        }

        return stars;
    };

    // Add media query for mobile styles
    const mobileStyles = `
        @media (max-width: 768px) {
            .rating-heading {
                font-size: 1.5rem !important;
                margin: 0 !important;
            }
            
            .rating-value {
                font-size: 1.5rem !important;
                margin: 0 !important;
            }
        }
    `;

    return (
        <div
            className="bg-white p-6 rounded-xl shadow-sm"
            style={{
                fontFamily: "'Roboto Mono', 'Courier New', monospace",
                fontWeight: 500,
            }}
        >
            {/* Add style tag for mobile styles */}
            <style>{mobileStyles}</style>

            {/* Main container with media query based flex direction */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                }}
            >
                {/* Star Rating Section - Now with responsive styles */}
                <div
                    style={{
                        fontFamily: "'Roboto Mono', 'Courier New', monospace'",
                    }}
                >
                    {/* This container will change based on media query */}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row' /* Default for mobile */,
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                        className="rating-container"
                    >
                        {/* Ratings Text */}
                        <h2
                            className="rating-heading"
                            style={{
                                margin: '0',
                                textAlign: 'center',
                                fontSize: '1.71428571rem', // Default size, will be overridden on mobile
                            }}
                        >
                            Ratings
                        </h2>

                        {/* Stars */}
                        <div
                            className="stars-container"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            {renderStars()}
                        </div>

                        {/* Average Rating Text */}
                        <h2
                            className="rating-value"
                            style={{
                                margin: '0',
                                textAlign: 'center',
                                fontSize: '1.71428571rem', // Default size, will be overridden on mobile
                            }}
                        >
                            {averageRating.toFixed(2)}
                        </h2>
                    </div>
                </div>

                {/* Histogram Section */}
                <div style={{ flex: 1 }}>
                    <div style={{ height: '12rem' }}>
                        <Bar data={chartData} options={chartOptions} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RatingDistribution;

import React, { useEffect, useState } from 'react';
import {
    Button,
    Form,
    Grid,
    Header,
    Icon,
    Message,
    Modal,
    Segment,
    Table,
} from 'semantic-ui-react';
import {
    deleteUser,
    fetchUsers,
    registerUser,
} from '../../../util/FetchUserData';

const UserManagementPanel = ({ handleUnauthorized }) => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newUser, setNewUser] = useState({
        username: '',
        password: '',
        email: '',
        privileges: 'user',
    });
    const [formError, setFormError] = useState(null);
    const [actionSuccess, setActionSuccess] = useState(null);

    // Options for user privileges dropdown
    const privilegeOptions = [
        { key: 'user', text: 'User', value: 'user' },
        { key: 'admin', text: 'Admin', value: 'admin' },
    ];

    // Load users on component mount
    useEffect(() => {
        loadUsers();
    }, []);

    // Function to load users
    const loadUsers = async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetchUsers();

            if (handleUnauthorized(response)) return;
            if (!response.ok) {
                throw new Error(
                    `Failed to fetch users: ${response.statusText}`,
                );
            }

            const data = await response.json();
            setUsers(data.data.users || []);
        } catch (err) {
            setError(err.message || 'Failed to load users');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    // Handle user deletion
    const handleDeleteUser = async (userId) => {
        try {
            const response = await deleteUser(userId);

            if (handleUnauthorized(response)) return;

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to delete user');
            }

            // Remove user from the list
            setUsers(users.filter((user) => user.user_id !== userId));
            setActionSuccess('User deleted successfully');

            // Clear success message after 3 seconds
            setTimeout(() => setActionSuccess(null), 3000);
        } catch (err) {
            setError(err.message || 'Failed to delete user');
            console.error(err);

            // Clear error message after 3 seconds
            setTimeout(() => setError(null), 3000);
        }
    };

    // Handle new user form input changes
    const handleInputChange = (e, { name, value }) => {
        setNewUser({ ...newUser, [name]: value });
    };

    // Handle form submission
    const handleSubmit = async () => {
        setFormError(null);

        // Basic validation
        if (!newUser.username || !newUser.password || !newUser.email) {
            setFormError('All fields are required');
            return;
        }

        try {
            const response = await registerUser(newUser);

            if (handleUnauthorized(response)) return;

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to register user');
            }

            // Close modal and reset form
            setIsModalOpen(false);
            setNewUser({
                username: '',
                password: '',
                email: '',
                privileges: 'user',
            });

            // Refresh user list
            loadUsers();

            setActionSuccess('User created successfully');

            // Clear success message after 3 seconds
            setTimeout(() => setActionSuccess(null), 3000);
        } catch (err) {
            setFormError(err.message || 'Failed to register user');
            console.error(err);
        }
    };

    return (
        <Segment>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Header as="h2">
                            <Icon name="users" />
                            <Header.Content>User Management</Header.Content>
                        </Header>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        {actionSuccess && (
                            <Message positive>
                                <Message.Header>Success</Message.Header>
                                <p>{actionSuccess}</p>
                            </Message>
                        )}

                        {error && (
                            <Message negative>
                                <Message.Header>Error</Message.Header>
                                <p>{error}</p>
                            </Message>
                        )}

                        <Button
                            primary
                            icon
                            labelPosition="left"
                            onClick={() => setIsModalOpen(true)}
                        >
                            <Icon name="add user" />
                            Add New User
                        </Button>

                        <Button icon labelPosition="left" onClick={loadUsers}>
                            <Icon name="refresh" />
                            Refresh
                        </Button>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        {loading ? (
                            <Segment placeholder loading>
                                <Header icon>
                                    <Icon name="user" />
                                    Loading users...
                                </Header>
                            </Segment>
                        ) : users.length === 0 ? (
                            <Segment placeholder>
                                <Header icon>
                                    <Icon name="user" />
                                    No users found
                                </Header>
                            </Segment>
                        ) : (
                            <Table celled>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>ID</Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Username
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Email
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Privileges
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Actions
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {users.map((user) => (
                                        <Table.Row key={user.user_id}>
                                            <Table.Cell>
                                                {user.user_id}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {user.username}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {user.email}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {user.privileges === 'admin' ? (
                                                    <span
                                                        style={{ color: 'red' }}
                                                    >
                                                        Admin
                                                    </span>
                                                ) : (
                                                    'User'
                                                )}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Button
                                                    negative
                                                    size="small"
                                                    icon="trash"
                                                    onClick={() =>
                                                        handleDeleteUser(
                                                            user.user_id,
                                                        )
                                                    }
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            {/* Modal for adding a new user */}
            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                size="small"
            >
                <Modal.Header>Add New User</Modal.Header>
                <Modal.Content>
                    <Form error={!!formError}>
                        <Form.Input
                            label="Username"
                            name="username"
                            value={newUser.username}
                            onChange={handleInputChange}
                            placeholder="Enter username"
                            required
                        />
                        <Form.Input
                            label="Email"
                            name="email"
                            type="email"
                            value={newUser.email}
                            onChange={handleInputChange}
                            placeholder="Enter email"
                            required
                        />
                        <Form.Input
                            label="Password"
                            name="password"
                            type="password"
                            value={newUser.password}
                            onChange={handleInputChange}
                            placeholder="Enter password"
                            required
                        />
                        <Form.Select
                            label="Privileges"
                            name="privileges"
                            options={privilegeOptions}
                            value={newUser.privileges}
                            onChange={handleInputChange}
                            placeholder="Select user privileges"
                        />

                        {formError && (
                            <Message error>
                                <Message.Header>Error</Message.Header>
                                <p>{formError}</p>
                            </Message>
                        )}
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setIsModalOpen(false)}>
                        Cancel
                    </Button>
                    <Button positive onClick={handleSubmit}>
                        Add User
                    </Button>
                </Modal.Actions>
            </Modal>
        </Segment>
    );
};

export default UserManagementPanel;

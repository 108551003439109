import { fetchWithRateLimitCheck } from './api';

const api_url = process.env.REACT_APP_GATEWAY_URL;

export function fetchFrontPageData() {
    return fetchWithRateLimitCheck(`${api_url}api/front_page`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    });
}

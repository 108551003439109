import React, { useState } from 'react';
import {
    Button,
    Container,
    Form,
    Grid,
    Header,
    Message,
    MessageHeader,
} from 'semantic-ui-react';
import Navbar from '../components/common/Navbar';
import { loginRequest } from '../util/FetchAuthentication';
import { useCsrfToken } from '../util/CsrfTokenProvider';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const AdminLoginPage = () => {
    const [loginError, setLoginError] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    // *** 2. Get the set function from the context hook ***
    const { setCsrfToken } = useCsrfToken();
    const navigate = useNavigate(); // Initialize useNavigate

    const handleLogin = async (e) => {
        e.preventDefault();
        // Clear previous token on new login attempt
        setCsrfToken(null);
        try {
            setLoginLoading(true);
            setLoginError(false);
            let response = await loginRequest(username, password);
            // It's generally safer to check response.ok for success
            // rather than just status codes, as fetch() doesn't throw
            // for 4xx/5xx errors by default.
            if (!response.ok) {
                // Try to parse error message from server if possible
                let errorResult;
                try {
                    errorResult = await response.json();
                } catch (parseError) {
                    // If parsing fails, use generic error
                    console.error(
                        'Failed to parse error response:',
                        parseError,
                    );
                    errorResult = {
                        message: `Login failed with status: ${response.status}`,
                    };
                }
                throw new Error(
                    errorResult?.message ||
                        `Login failed with status: ${response.status}`,
                );
            }

            let result = await response.json(); // Parse successful response

            // Check for success based on your API's response structure
            // Also check if the csrf_access_token exists in the response body
            if (
                result &&
                result.status === 'success' &&
                result.csrf_access_token
            ) {
                console.log('Login Success:', result);
                // *** 3. Set the CSRF token in the context ***
                setCsrfToken(result.csrf_access_token);
                console.log(`CSRF Token Set: ${result.csrf_access_token}`); // For debugging

                // Proceed with redirect only after setting the token
                navigate('/admin'); // <<< Use navigate for client-side routing
            } else {
                // Handle cases where login seemed successful but token/expected data is missing
                console.error(
                    'Login successful but response missing data or CSRF token:',
                    result,
                );
                setLoginError(true);
                alert(
                    result?.message ||
                        'Login succeeded but data was incomplete.',
                );
                // Ensure token is cleared
                setCsrfToken(null);
            }
        } catch (error) {
            console.error('Login Error:', error);
            setLoginError(true);
            // Display the error message from the catch block if available
            alert(error.message || 'An error occurred during login.');
            // Ensure token is cleared on any error
            setCsrfToken(null);
        } finally {
            setLoginLoading(false);
        }
    };

    // No changes needed in the JSX return part
    return (
        <>
            <Navbar />
            <Container style={{ padding: '2em 1em' }}>
                <Grid textAlign="center" style={{ minHeight: '80vh' }}>
                    <Grid.Column style={{ maxWidth: 450 }}>
                        <Header
                            as="h2"
                            color="culpa-blue-text"
                            textAlign="center"
                        >
                            Log-in to your admin account
                        </Header>
                        <Form size="large">
                            <Form.Input
                                fluid
                                icon="user"
                                iconPosition="left"
                                placeholder="Username"
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <Form.Input
                                fluid
                                icon="lock"
                                iconPosition="left"
                                placeholder="Password"
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Button
                                className="culpa-orange"
                                fluid
                                size="large"
                                onClick={handleLogin}
                                loading={loginLoading}
                            >
                                Login
                            </Button>
                            {loginError && (
                                <Message negative>
                                    <MessageHeader>Login Failed</MessageHeader>
                                    <p>
                                        Please verify your credentials or check
                                        the console for errors.
                                    </p>
                                </Message>
                            )}
                        </Form>
                    </Grid.Column>
                </Grid>
            </Container>
        </>
    );
};

export default AdminLoginPage;

import React from 'react';
import { Container } from 'semantic-ui-react';
import styled from 'styled-components';
import Footer from '../components/common/Footer';
import Navbar from '../components/common/Navbar';

const ContentWrapper = styled.div`
    padding-top: 4em;
    padding-left: 25%;
    padding-right: 25%;
    flex: 1;

    @media (max-width: 768px) {
        padding-left: 5%;
        padding-right: 5%;
    }
`;

const FlexContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const FaqsPage = () => (
    <>
        <Navbar />
        <FlexContainer style={{ width: '100vw', paddingBottom: '5em' }}>
            <ContentWrapper>
                <h1>FAQs</h1>

                <h2>What information should I include in a review?</h2>
                <p style={{ fontSize: '1.2em', fontWeight: '400' }}>
                    A review could include anything you would want to know about
                    that course — structure and components of the class,
                    teaching style of the instructor, course load, grade
                    breakdown, etc. Tell us how you benefited from this course
                    (or not), and compare it to other courses in similar
                    disciplines offered by Columbia. Please avoid writing only
                    vague, subjective statements like “This professor was
                    great!” or “AVOID LIKE THE PLAGUE!!!” Comments like these
                    provide little actual substance about your experience with a
                    course or professor, and are not the most useful for other
                    students.
                </p>

                <h2>How often does CULPA update?</h2>
                <p style={{ fontSize: '1.2em', fontWeight: '400' }}>
                    We receive submissions all year round and post them as soon
                    as practicable. It may be the case that a particular
                    professor or course has not been updated for the past
                    semester. Our experience is that incoming reviews are
                    distributed among thousands of professors and courses at
                    Columbia, and as such, only a few of the reviews are
                    allocated to a given professor or course every semester.
                </p>

                <h2>Why hasn't the review I submitted been posted?</h2>
                <p style={{ fontSize: '1.2em', fontWeight: '400' }}>
                    If your review is not posted within two weeks, you may
                    contact us at{' '}
                    <a href="mailto:admin@culpa.info">admin@culpa.info</a>. Want
                    to help expedite the process? Join the CULPA team and
                    contribute to the platform! For more information on the
                    CULPA team, see the <a href="/join">Join the Team</a> page.
                </p>

                <h2>How are reviews moderated?</h2>
                <p style={{ fontSize: '1.2em', fontWeight: '400' }}>
                    Our terms of use do not permit the submission of libelous,
                    slanderous, malicious, or offensive content. Occasionally,
                    submissions are received that violate these rules and we try
                    to exclude those from distribution. (No system is perfect,
                    and so we are grateful when users{' '}
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSeAx0ZSdPR483Ap76AJndq3DYj-AaZmTFAzn57rhjQMHDrieg/viewform">
                        point out to us
                    </a>{' '}
                    distributed content that violates our rules). Additionally,
                    we conduct basic accuracy checks for course and professor
                    data submitted through reviews. The CULPA team reserves the
                    right to withhold, edit, or remove reviews in accordance
                    with our policies to provide a comfortable experience for
                    all users, though our experience is that this is rarely a
                    step we decide to take. Should you notice a review on the
                    platform that violates our <a href="/terms">Terms of Use</a>
                    , please report the content{' '}
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLScNbj-6Ez0gQ7keVRI7DBY5Kgl-6ZM87ATAvstYIxdiTRxr0g/viewform">
                        here
                    </a>
                    .
                </p>

                <h2>
                    Does CULPA accept and feature reviews for TAs and graduate
                    students?
                </h2>
                <p style={{ fontSize: '1.2em', fontWeight: '400' }}>
                    CULPA has previously accepted reviews for TAs and graduate
                    students, and you can still find all of those reviews on the
                    site. Moving forward, reviews submitted to CULPA must be
                    linked only to the course and the professor that teaches it.
                    However, you are encouraged to include any information on
                    TAs and graduate student instructors in your reviews should
                    you find the information relevant.
                </p>

                <h2>
                    I am an instructor who has found inaccurate reviews about
                    me.
                </h2>
                <p style={{ fontSize: '1.2em', fontWeight: '400' }}>
                    Please report the incorrect content to the CULPA team{' '}
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLScNbj-6Ez0gQ7keVRI7DBY5Kgl-6ZM87ATAvstYIxdiTRxr0g/viewform">
                        here
                    </a>
                    . We will review your concern and address it accordingly. As
                    the opinions on CULPA belong entirely to the individuals,
                    not the administrators of CULPA, you should feel free to
                    contact us about removing something written about you that
                    is inaccurate.
                </p>

                <h2>How are “nuggets” assigned?</h2>
                <p style={{ fontSize: '1.2em', fontWeight: '400' }}>
                    Cookies are granted to professors according to the average
                    rating of their reviews.
                </p>

                <h2>
                    I found a bug, or a duplicate professor/course, on the
                    website, how can I report the issue?
                </h2>
                <p style={{ fontSize: '1.2em', fontWeight: '400' }}>
                    Please let us know{' '}
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSeAx0ZSdPR483Ap76AJndq3DYj-AaZmTFAzn57rhjQMHDrieg/viewform">
                        here
                    </a>
                    .
                </p>

                <h2>What is CULPA's privacy policy?</h2>
                <p style={{ fontSize: '1.2em', fontWeight: '400' }}>
                    See our <a href="/privacy"> Privacy Policy</a>.
                </p>
            </ContentWrapper>
        </FlexContainer>
        <br />
        <br />
        <Footer />
    </>
);

export default FaqsPage;

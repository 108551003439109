import React from 'react';

const NewProfessorRow = ({ setIsNewProfessor, setInput, setNewProfessor }) => {
    return (
        <div
            onClick={() => {
                setIsNewProfessor(true);
                setInput('New Professor');
                setNewProfessor({});
            }}
        >
            <strong>{"Can't find the Professor? Add a new Professor"}</strong>
        </div>
    );
};

export default NewProfessorRow;

import React, { useState, useEffect } from 'react';
import { Form, FormField, Input, Label } from 'semantic-ui-react';

// Reusable error label component
const FieldErrorLabel = ({ messages }) => {
    if (!messages || messages.length === 0) return null;
    return (
        <Label
            basic
            color="red"
            pointing
            className="field-error"
            style={{ marginTop: '0.25em' }}
        >
            {messages.join('; ')}
        </Label>
    );
};

// Pass down fieldErrors and the parent's clearFieldErrors or a specific clearer
const NewCourseForm = ({
    setNewCourse,
    fieldErrors = {},
    clearFieldErrors,
}) => {
    // Local state for this form's inputs
    const [localData, setLocalData] = useState({
        course_name: '',
        course_code: '',
    });

    // Update parent state whenever local state changes
    useEffect(() => {
        setNewCourse(localData);
    }, [localData, setNewCourse]);

    // Handle input changes locally and clear errors
    const handleChange = (event) => {
        const { name, value } = event.target;
        setLocalData((prev) => ({ ...prev, [name]: value }));

        // Clear the specific nested error
        if (clearFieldErrors) {
            clearFieldErrors(`new_course_request.${name}`);
        }
    };

    return (
        <Form
            size="large"
            style={{
                marginTop: '1em',
                padding: '1em',
                border: '1px solid rgba(0,0,0,.1)',
                borderRadius: '0.28rem',
            }}
        >
            {/* Course Name */}
            <FormField error={!!fieldErrors.course_name}>
                <label>Course Name</label>
                <Input
                    placeholder="e.g., Introduction to Computer Science"
                    name="course_name"
                    value={localData.course_name} // Controlled input
                    onChange={handleChange}
                />
                <FieldErrorLabel messages={fieldErrors.course_name} />
            </FormField>

            {/* Course Code */}
            <FormField error={!!fieldErrors.course_code}>
                <label>Course Code</label>
                <Input
                    placeholder="e.g., COMS W1004"
                    name="course_code"
                    value={localData.course_code} // Controlled input
                    onChange={handleChange}
                />
                <FieldErrorLabel messages={fieldErrors.course_code} />
            </FormField>
        </Form>
    );
};

export default NewCourseForm;

import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Image } from 'semantic-ui-react';
import BookmarkIcon from '../../icons/bookmark.svg';
import CulpaIcon from '../../icons/culpa.svg';
import EmailIcon from '../../icons/email.svg';
import FacebookIcon from '../../icons/facebook.svg';

const Footer = () => {
    return (
        <>
            <Grid
                columns={3}
                stackable
                className="culpa-blue"
                style={{ 'padding-left': '10%', 'padding-right': '10%' }}
            >
                <Grid.Row className="footer-text">
                    <Grid.Column
                        mobile={16}
                        tablet={16}
                        computer={4}
                        style={{
                            backgroundColor: '#1d2c4d',
                            padding: '2em',
                            color: 'white',
                            textAlign: 'center',
                        }}
                    >
                        <img src={CulpaIcon} alt="Culpa Icon" />
                        <p>© 1997 - 2025</p>
                    </Grid.Column>
                    <Grid.Column
                        mobile={16}
                        tablet={16}
                        computer={6}
                        style={{
                            color: 'white',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            'align-items': 'center',
                            'padding-bottom': '2em',
                            'padding-top': '2em',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '250px',
                            }}
                        >
                            <Link
                                to={'/about'}
                                style={{ marginBottom: '0px', color: 'white' }}
                            >
                                About
                            </Link>
                            <Link
                                to={'/about'}
                                style={{ marginBottom: '0px', color: 'white' }}
                            >
                                How to Use
                            </Link>
                            <Link
                                to={'/join'}
                                style={{ marginBottom: '0px', color: 'white' }}
                            >
                                Join the Team
                            </Link>
                        </div>
                    </Grid.Column>
                    <Grid.Column
                        mobile={16}
                        tablet={16}
                        computer={6}
                        style={{
                            color: 'white',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            'align-items': 'center',
                            'padding-bottom': '2em',
                            'padding-top': '2em',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '250px',
                            }}
                        >
                            <p
                                style={{
                                    marginBottom: '0px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <Image
                                    src={EmailIcon}
                                    style={{ paddingRight: '0.5em' }}
                                ></Image>
                                <Link
                                    to="mailto:admin@culpa.info"
                                    style={{ color: 'white' }}
                                >
                                    admin@culpa.info
                                </Link>
                            </p>
                            <p
                                style={{
                                    marginBottom: '0px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <Image
                                    src={FacebookIcon}
                                    style={{ paddingRight: '0.5em' }}
                                ></Image>
                                <Link
                                    to="https://www.facebook.com/"
                                    style={{ color: 'white' }}
                                >
                                    Facebook
                                </Link>
                            </p>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {' '}
                                {/* Added wrapper */}
                                <Image
                                    src={BookmarkIcon}
                                    style={{ paddingRight: '0.5em' }}
                                ></Image>
                                <span
                                    style={{
                                        paddingLeft: '0px',
                                        paddingRight: '8pt',
                                    }}
                                >
                                    Bookmark
                                </span>
                                <span>CULPA</span>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
};

export default Footer;

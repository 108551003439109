import getCookie from './FetchAuthentication';
import { fetchWithRateLimitCheck } from './api';

const api_url = process.env.REACT_APP_GATEWAY_URL;

// Helper function to create URLs with query parameters
const createUrl = (endpoint, params = {}) => {
    const queryString = new URLSearchParams(params).toString();
    return `${api_url}${endpoint}${queryString ? `?${queryString}` : ''}`;
};

// This file contains functions that fetch data from the backend related to professors
export function fetchCourseSummaries(page) {
    return fetchWithRateLimitCheck(
        createUrl('api/course/summary/all', { page: page }),
        {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        },
    );
}

export function fetchAllCoursesForProfessor(professorId) {
    return fetchWithRateLimitCheck(
        `${api_url}api/course/taught/${professorId}`,
        {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        },
    );
}

export function fetchCourseSummary(courseId) {
    return fetchWithRateLimitCheck(`${api_url}api/course/summary/${courseId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    });
}

export function fetchPendingCourses(page) {
    return fetchWithRateLimitCheck(
        createUrl('api/course/pending', { page: page }),
        {
            method: 'GET',
            headers: {
                'X-CSRF-TOKEN': getCookie('csrf_access_token'),
            },
            credentials: 'include',
        },
    );
}

export function fetchCoursesMissingCourseCode(page) {
    return fetchWithRateLimitCheck(
        createUrl('api/course/missing_course_code', { page: page }),
        {
            method: 'GET',
            headers: {
                'X-CSRF-TOKEN': getCookie('csrf_access_token'),
            },
            credentials: 'include',
        },
    );
}

export function fetchCourseSearch(searchQuery, maxResults = 10) {
    return fetchWithRateLimitCheck(
        createUrl('api/course/search', {
            queryString: searchQuery,
            maxResults: maxResults,
        }),
        {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        },
    );
}

export function approveCourse(courseId, newStatus, csrfToken) {
    return fetchWithRateLimitCheck(`${api_url}api/course/approve`, {
        method: 'PUT',
        headers: {
            'X-CSRF-TOKEN': csrfToken,
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ course_id: courseId, new_status: newStatus }),
    });
}

export function fetchCoursePageData(courseId) {
    return fetchWithRateLimitCheck(
        `${api_url}api/course_page/card/${courseId}`,
        {
            method: 'GET',
        },
    );
}

export function updateCourseCode(courseId, courseCode, csrfToken) {
    return fetchWithRateLimitCheck(`${api_url}api/course/course_code`, {
        method: 'PUT',
        headers: {
            'X-CSRF-TOKEN': csrfToken,
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            course_id: courseId,
            new_course_code: courseCode,
        }),
    });
}

import getCookie from './FetchAuthentication';
import { fetchWithRateLimitCheck } from './api'; // Import our custom fetch function

const api_url = process.env.REACT_APP_GATEWAY_URL;

// Helper function to create the full URL with query parameters
const createUrl = (endpoint, params = {}) => {
    const queryString = new URLSearchParams(params).toString();
    return `${api_url}${endpoint}${queryString ? `?${queryString}` : ''}`;
};

export function fetchAllReviewsForProfessor(
    professorId,
    page,
    sortKey,
    courseFilter,
) {
    const params = {
        page: page,
        sort_key: sortKey,
        course_filter: courseFilter,
    };
    return fetchWithRateLimitCheck(
        createUrl(`api/review/professor/${professorId}`, params),
        {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        },
    );
}

export function fetchNumberOfReviewsForProfessor(professorId, courseFilter) {
    const params = { course_filter: courseFilter };
    return fetchWithRateLimitCheck(
        createUrl(
            `api/review/professor/number_of_reviews/${professorId}`,
            params,
        ),
        {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        },
    );
}

export function fetchKMostRecentReviews(k) {
    return fetchWithRateLimitCheck(`${api_url}api/review/recent/${k}`, {
        method: 'GET',
    });
}

export function fetchReviewSpotlightForProfessor(professorId) {
    return fetchWithRateLimitCheck(
        `${api_url}api/review/professor/spotlight/${professorId}`,
        {
            method: 'GET',
        },
    );
}

export function fetchAllReviewsForCourse(
    courseId,
    page,
    sortKey,
    professorFilter,
) {
    const params = {
        page: page,
        sort_key: sortKey,
        professor_filter: professorFilter,
    };
    return fetchWithRateLimitCheck(
        createUrl(`api/review/course/${courseId}`, params),
        {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        },
    );
}

export function fetchNumberOfReviewsForCourse(courseId, professorFilter) {
    const params = { professor_filter: professorFilter };
    return fetchWithRateLimitCheck(
        createUrl(`api/review/course/number_of_reviews/${courseId}`, params),
        {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        },
    );
}

export function fetchPendingReviews(page) {
    const params = { page: page };
    return fetchWithRateLimitCheck(createUrl(`api/review/pending`, params), {
        method: 'GET',
        headers: {
            'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
        credentials: 'include',
    });
}

// INSERT METHODS
export function postNewPendingReview(review) {
    return fetchWithRateLimitCheck(`${api_url}api/review/new`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(review),
    });
}

export function approveReview(reviewId, newStatus, csrfToken) {
    return fetchWithRateLimitCheck(`${api_url}api/flag`, {
        method: 'PUT',
        headers: {
            'X-CSRF-TOKEN': csrfToken,
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ review_id: reviewId, new_status: newStatus }),
    });
}

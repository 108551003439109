import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, CardContent, Grid, Header } from 'semantic-ui-react';
import '../../../App.css';
import { fetchProfessorCardData } from '../../../util/FetchProfessorData';
import LoaderAnimation from '../../common/LoaderAnimation';
import SyllabusUploadModal from '../../common/modal/SyllabusUploadModal';
import ProfessorTitle from '../../common/titles/ProfessorTitle';
import RatingDistribution from '../../RatingDistribution';
import ProfessorSyllabi from './ProfessorSyllabi';

const ProfessorCard = ({ professor_id, courses, setCourses, setQuotes }) => {
    const [syllabusModalOpen, setSyllabusModalOpen] = useState(false);
    const [cardData, setCardData] = useState(null);
    const [cardDataLoading, setCardDataLoading] = useState(true);
    const navigate = useNavigate();

    const handleUploadComplete = () => {
        setSyllabusModalOpen(false);
    };

    useEffect(() => {
        const getProfessorCardData = async () => {
            setCardDataLoading(true);
            try {
                const response = await fetchProfessorCardData(professor_id);
                const result = await response.json();
                setCardData(result);
                setCourses(result.courses_taught);
                setQuotes(result.quotes);
            } catch (error) {
                console.error(error);
            } finally {
                setCardDataLoading(false);
            }
        };

        getProfessorCardData();
    }, [professor_id, setCourses]);

    const handleReviewClick = () => {
        if (
            cardData &&
            cardData.professor_summary &&
            cardData.professor_summary.professor_header
        ) {
            const professorInfo = {
                professor_id:
                    cardData.professor_summary.professor_header.professor_id,
                first_name:
                    cardData.professor_summary.professor_header.first_name,
                last_name:
                    cardData.professor_summary.professor_header.last_name,
                search_result_type: 'Professor',
            };

            navigate('/review/new', {
                state: { professorData: professorInfo },
            });
        }
    };

    if (cardDataLoading || cardData == null) {
        return (
            <Card fluid>
                <CardContent>
                    <LoaderAnimation />
                </CardContent>
            </Card>
        );
    }

    return (
        <>
            <Card fluid>
                <CardContent>
                    <Header as="h1">
                        <ProfessorTitle
                            professor={
                                cardData.professor_summary.professor_header
                            }
                            includeBadge={true}
                        />
                    </Header>
                </CardContent>
                <CardContent>
                    <Grid columns={2} stackable>
                        <Grid.Row>
                            <Grid.Column mobile={16} tablet={8} computer={8}>
                                <Header as="h2" content="Courses" />
                                {cardData.courses_taught.map((course) => (
                                    <p
                                        key={course.course_id}
                                        className="no-margin-bottom"
                                    >
                                        <Link
                                            to={`/course/${course.course_id}`}
                                        >
                                            {`[${course.course_code}] ${course.course_name}`}
                                        </Link>
                                    </p>
                                ))}
                                <br />
                                <Button
                                    className="orange-button"
                                    onClick={handleReviewClick}
                                >
                                    {`WRITE A REVIEW FOR ${cardData.professor_summary.professor_header.first_name.toUpperCase()} ${cardData.professor_summary.professor_header.last_name.toUpperCase()}`}
                                </Button>
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={8} computer={8}>
                                <RatingDistribution
                                    professorId={
                                        cardData.professor_summary
                                            .professor_header.professor_id
                                    }
                                    mratings={cardData.ratings_histogram}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Header as="h2" content="Syllabi" />
                                <ProfessorSyllabi syllabi={cardData.syllabi} />
                                <br />
                                <Button
                                    className="blue-button"
                                    onClick={() => setSyllabusModalOpen(true)}
                                >
                                    Submit a Syllabus
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </CardContent>
            </Card>

            <SyllabusUploadModal
                open={syllabusModalOpen}
                onClose={() => setSyllabusModalOpen(false)}
                courses={courses}
                onUploadComplete={handleUploadComplete}
            />
        </>
    );
};

export default ProfessorCard;

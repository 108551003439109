import React from 'react';

const CourseTitle = ({ course }) => {
    return (
        <>
            <a
                style={{ 'margin-right': '10px', color: '#4071CA' }}
                href={`/course/${course.course_id}`}
            >
                {`[${course.course_code}] ${course.course_name}`}
            </a>
        </>
    );
};

export default CourseTitle;

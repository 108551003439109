import React, { useState, useEffect } from 'react';
import { Form, FormField, Input, Label } from 'semantic-ui-react';

// Reusable error label component (can be imported if moved to a common location)
const FieldErrorLabel = ({ messages }) => {
    if (!messages || messages.length === 0) return null;
    return (
        <Label
            basic
            color="red"
            pointing
            className="field-error"
            style={{ marginTop: '0.25em' }}
        >
            {messages.join('; ')}
        </Label>
    );
};

// Pass down fieldErrors and the parent's clearFieldErrors function or a specific clearer
const NewProfessorForm = ({
    setNewProfessor,
    fieldErrors = {},
    clearFieldErrors,
}) => {
    // Local state to manage input values within this form
    const [localData, setLocalData] = useState({
        first_name: '',
        last_name: '',
        uni: '',
    });

    // Update parent state whenever local state changes
    useEffect(() => {
        setNewProfessor(localData);
    }, [localData, setNewProfessor]);

    // Handle input changes locally and clear specific errors
    const handleChange = (event) => {
        const { name, value } = event.target;
        setLocalData((prev) => ({ ...prev, [name]: value }));

        // Clear the specific nested error when the user types in the field
        // Assumes parent passed down a function that knows how to clear nested errors
        if (clearFieldErrors) {
            // Construct the full field name as expected in the parent's state
            clearFieldErrors(`new_professor_request.${name}`);
        }
    };

    return (
        // Add some padding/margin when the form appears
        <Form
            size="large"
            style={{
                marginTop: '1em',
                padding: '1em',
                border: '1px solid rgba(0,0,0,.1)',
                borderRadius: '0.28rem',
            }}
        >
            {/* First Name */}
            <FormField error={!!fieldErrors.first_name}>
                <label>First Name</label>
                <Input
                    placeholder="First Name"
                    name="first_name"
                    value={localData.first_name} // Controlled input
                    onChange={handleChange}
                />
                <FieldErrorLabel messages={fieldErrors.first_name} />
            </FormField>

            {/* Last Name */}
            <FormField error={!!fieldErrors.last_name}>
                <label>Last Name</label>
                <Input
                    placeholder="Last Name"
                    name="last_name"
                    value={localData.last_name} // Controlled input
                    onChange={handleChange}
                />
                <FieldErrorLabel messages={fieldErrors.last_name} />
            </FormField>

            {/* UNI */}
            <FormField error={!!fieldErrors.uni}>
                <label>UNI</label>
                <Input
                    placeholder="e.g., jd1234"
                    name="uni"
                    value={localData.uni} // Controlled input
                    onChange={handleChange}
                />
                <FieldErrorLabel messages={fieldErrors.uni} />
            </FormField>
        </Form>
    );
};

export default NewProfessorForm;

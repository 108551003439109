import React from 'react';
import { Label } from 'semantic-ui-react';

const COLORS_MAP = {
    Professor: '#FFC800',
    Course: '#92B6E6',
};

const SearchRow = ({ content, label }) => {
    var path, text;
    if (label === 'Professor') {
        path = `/professor/${content.professor_header.professor_id}`;
        text = `${content.professor_header.first_name} ${content.professor_header.last_name}`;
    } else if (label === 'Course') {
        path = `/course/${content.course_header.course_id}`;
        text = `[${content.course_header.course_code}] ${content.course_header.course_name}`;
    }

    return (
        <a href={path}>
            <div
                className="flex-space-between"
                style={{ alignItems: 'center' }}
            >
                {text}{' '}
                <Label
                    style={{ backgroundColor: COLORS_MAP[label] }}
                    content={label}
                />
            </div>
        </a>
    );
};

export default SearchRow;

import { createContext, useContext, useState } from 'react';

// Create a context for rate limiting state
export const RateLimitContext = createContext({
    isRateLimited: false,
    setIsRateLimited: () => {},
});

// Provider component that will wrap your application
export const RateLimitProvider = ({ children }) => {
    const [isRateLimited, setIsRateLimited] = useState(false);

    return (
        <RateLimitContext.Provider value={{ isRateLimited, setIsRateLimited }}>
            {children}
        </RateLimitContext.Provider>
    );
};

// Hook to use the rate limit context
export const useRateLimit = () => useContext(RateLimitContext);

// Custom fetch function that handles rate limiting
export const fetchWithRateLimitCheck = async (url, options = {}) => {
    try {
        const response = await fetch(url, options);

        // Check if the response indicates rate limiting
        if (response.status === 429) {
            // Get the context setter function from the global window object
            const { setIsRateLimited } = window.rateLimitState || {};

            if (setIsRateLimited) {
                // Set the global rate limited state to true
                setIsRateLimited(true);
            }
        }

        // If the response is not ok and not a rate limit error, we still want to handle it
        if (!response.ok && response.status !== 429) {
            console.error(
                `API error: ${response.status} ${response.statusText}`,
            );
        }

        return response;
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
};

import { fetchWithRateLimitCheck } from './api'; // Import our custom fetch function
import getCookie from './FetchAuthentication';

const api_url = process.env.REACT_APP_GATEWAY_URL;

export function fetchAllCounts() {
    return fetchWithRateLimitCheck(`${api_url}api/admin_page/pending/counts`, {
        method: 'GET',
        headers: {
            'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
        credentials: 'include',
    });
}

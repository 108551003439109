import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledMenuButton = styled(Button)`
    color: #2d2d2d !important;
    border: none !important;
    width: 42px;
    height: 42px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: transform 0.2s ease;

    &:active {
        transform: scale(0.95);
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }

    i.icon {
        margin: 0 !important;
        font-size: 1.4rem;
    }

    @media (min-width: 769px) {
        margin-left: auto;
    }
`;

const MenuButton = ({ onClick }) => (
    <StyledMenuButton onClick={onClick}>
        <Icon name="bars" size="small" />
    </StyledMenuButton>
);

export default MenuButton;

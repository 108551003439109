import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Button,
    Container,
    Dropdown,
    Grid,
    Header,
    Label,
} from 'semantic-ui-react';
import ProfessorCard from '../components/cards/professor-cards/ProfessorCard';
import QuoteCard from '../components/cards/quotes-cards/QuoteCard';
import AgreedAndDisagreedReviews from '../components/cards/review-cards/AgreedAndDisagreedReviews';
import Review from '../components/cards/review-cards/ReviewCard';
import Footer from '../components/common/Footer';
import LoaderAnimation from '../components/common/LoaderAnimation';
import Navbar from '../components/common/Navbar';
import { fetchAllReviewsForProfessor } from '../util/FetchReviewData';

const sortOptions = [
    { key: 'newest', text: 'Newest', value: 'newest' },
    { key: 'oldest', text: 'Oldest', value: 'oldest' },
    { key: 'most_agreed', text: 'Most Agreed', value: 'most_agreed' },
    { key: 'most_disagreed', text: 'Most Disagreed', value: 'most_disagreed' },
    { key: 'most_funny', text: 'Most Funny', value: 'most_funny' },
];

const ProfessorPage = () => {
    const { professorId } = useParams();

    const [numberOfReviews, setNumberOfReviews] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [reviewsLoading, setReviewsLoading] = useState(false);
    const [reviews, setReviews] = useState([]);
    const [hasMoreReviews, setHasMoreReviews] = useState(true);

    const [professorSpotlight, setProfessorSpotlight] = useState(null);
    const [sortKey, setSortKey] = useState(null);
    const [courses, setCourses] = useState([]);
    const [courseFilter, setCourseFilter] = useState(null);

    const [quotes, setQuotes] = useState([]);

    const loadMoreReviews = () => {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
    };

    const handleSortChange = (event, data) => {
        setSortKey(data.value);
        // Reset page and reviews when changing sort
        setPageNumber(1);
        setReviews([]);
        setHasMoreReviews(true);
    };

    const handleCourseFilterChange = (event, data) => {
        // If "All Courses" is selected, data.value will be an empty string
        setCourseFilter(data.value === '' ? null : data.value);
        // Reset page and reviews when changing filter
        setPageNumber(1);
        setReviews([]);
        setHasMoreReviews(true);
    };

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                setReviewsLoading(true);
                const response = await fetchAllReviewsForProfessor(
                    professorId,
                    pageNumber,
                    sortKey,
                    courseFilter,
                );
                const result = await response.json();

                if (pageNumber === 1) {
                    // If first page, replace reviews
                    setReviews(result['reviews']);
                } else {
                    // Otherwise, append new reviews
                    setReviews((prevReviews) => [
                        ...prevReviews,
                        ...result['reviews'],
                    ]);
                }

                setNumberOfReviews(result['number_of_reviews']);
                setProfessorSpotlight(result['reviews_spotlight']);

                // Calculate if there are more reviews to load
                const totalPages = Math.ceil(result['number_of_reviews'] / 5);
                setHasMoreReviews(pageNumber < totalPages);
            } catch (error) {
                console.error(error);
            } finally {
                setReviewsLoading(false);
            }
        };

        fetchReviews();
    }, [professorId, pageNumber, sortKey, courseFilter]);

    // Build dropdown options for courses with an "All Courses" default option
    const courseOptions = [
        { key: 'all', text: 'All Courses', value: '' },
        ...courses.map((course) => ({
            key: course.course_id,
            text: course.course_name,
            value: course.course_id,
        })),
    ];

    return (
        <div style={{ width: '100%' }}>
            <Navbar />

            <br />
            <br />

            <Container>
                <ProfessorCard
                    professor_id={professorId}
                    courses={courses}
                    setCourses={setCourses}
                    setQuotes={setQuotes}
                />
                <br />
                <AgreedAndDisagreedReviews
                    professorSpotlight={professorSpotlight}
                    spotlightLoading={reviewsLoading}
                />
            </Container>

            <br />
            <br />

            <Container>
                <QuoteCard quotes={quotes} />
            </Container>

            <br />
            <br />

            <Container>
                <Header
                    as="h2"
                    style={{ margin: '0 auto', textAlign: 'center' }}
                >
                    All Reviews
                </Header>
                <br />
                <Grid
                    container
                    stackable
                    columns={2}
                    style={{ marginBottom: '1rem' }}
                >
                    <Grid.Row>
                        <Grid.Column
                            textAlign="left"
                            style={{ paddingLeft: '0' }}
                        >
                            <Dropdown
                                trigger={
                                    sortKey ? (
                                        <Label
                                            color="gray"
                                            style={{
                                                marginLeft: '0.5em',
                                                flex: 1,
                                            }}
                                        >
                                            {
                                                sortOptions.find(
                                                    (option) =>
                                                        option.value ===
                                                        sortKey,
                                                )?.text
                                            }
                                        </Label>
                                    ) : (
                                        'Sort Reviews'
                                    )
                                }
                                selection
                                clearable
                                options={sortOptions}
                                value={sortKey}
                                onChange={handleSortChange}
                                fluid
                            />
                        </Grid.Column>
                        <Grid.Column textAlign="left">
                            <Dropdown
                                trigger={
                                    courseFilter ? (
                                        <Label
                                            color="gray"
                                            style={{
                                                marginLeft: '0.5em',
                                                flex: 1,
                                            }}
                                        >
                                            {
                                                courseOptions.find(
                                                    (option) =>
                                                        option.value ===
                                                        courseFilter,
                                                )?.text
                                            }
                                        </Label>
                                    ) : (
                                        'Filter by Course'
                                    )
                                }
                                selection
                                clearable
                                options={courseOptions}
                                value={
                                    courseFilter === null ? '' : courseFilter
                                }
                                onChange={handleCourseFilterChange}
                                fluid
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
            {reviewsLoading && reviews.length === 0 ? (
                <Container>
                    <LoaderAnimation />
                </Container>
            ) : (
                <Container>
                    {/* Wrap the review list in a Grid */}
                    <Grid stackable>
                        {' '}
                        {/* Add this */}
                        <Grid.Row>
                            {' '}
                            {/* Add this */}
                            <Grid.Column width={16}>
                                {' '}
                                {/* Add this to ensure full width */}
                                {reviews.map((review, index) => (
                                    <React.Fragment key={index}>
                                        <Review
                                            review={review}
                                            applyDescriptionStyling={false}
                                            showCourse={true}
                                        />
                                        {/* Consider removing this <br/> if grid spacing is sufficient, */}
                                        {/* or adjust margins on the ReviewCard or Grid items */}
                                        <br />
                                    </React.Fragment>
                                ))}
                                {/* Load More Button */}
                                {hasMoreReviews && (
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            margin: '2rem 0',
                                        }}
                                    >
                                        <Button
                                            className="load-more-button"
                                            onClick={loadMoreReviews}
                                            loading={reviewsLoading}
                                            disabled={reviewsLoading}
                                        >
                                            Load More
                                        </Button>
                                    </div>
                                )}
                                {/* No more reviews message */}
                                {!hasMoreReviews && reviews.length > 0 && (
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            margin: '2rem 0',
                                        }}
                                    >
                                        <p>No more reviews to load</p>
                                    </div>
                                )}
                            </Grid.Column>{' '}
                            {/* Close Grid.Column */}
                        </Grid.Row>{' '}
                        {/* Close Grid.Row */}
                    </Grid>{' '}
                    {/* Close Grid */}
                </Container>
            )}

            <br />
            <br />
            <Footer />
        </div>
    );
};

export default ProfessorPage;

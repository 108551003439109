import PropTypes from 'prop-types';
import React from 'react';
import { Image } from 'semantic-ui-react';
import CloseQuote from '../../../icons/quotes/close-quotes.svg';
import OpenQuote from '../../../icons/quotes/open-quotes.svg';
import CourseTitle from '../../common/titles/CourseTitle';
import ProfessorTitle from '../../common/titles/ProfessorTitle';

const Quote = ({ quote }) => {
    return (
        <>
            <div
                className="quote-container quote-text"
                style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    paddingTop: '20px',
                }}
            >
                <Image
                    src={OpenQuote}
                    className="quote-icon quote-left"
                    style={{ alignSelf: 'flex-start' }}
                />
                <span>{quote.content}</span>
                <Image
                    src={CloseQuote}
                    className="quote-icon quote-right"
                    style={{ alignSelf: 'flex-start' }}
                />
            </div>
            <div className="quote-footer" style={{ paddingBottom: '20px' }}>
                <ProfessorTitle professor={quote.professor_header} margin={0} />{' '}
                in <CourseTitle course={quote.course_header} />
            </div>
        </>
    );
};

Quote.propTypes = {
    text: PropTypes.string.isRequired,
    author: PropTypes.string,
};

Quote.defaultProps = {
    author: '',
};

export default Quote;

import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardContent, CardHeader, Message } from 'semantic-ui-react';
import {
    approveCourse,
    fetchCourseSearch,
} from '../../../util/FetchCourseData';
import ApproveOrRejectFooter from '../../admin/ApproveOrRejectFooter';
import CourseTitle from '../../common/titles/CourseTitle';
import CoursesWithSimilarNamesTable from '../../tables/CoursesWithSimilarNamesTable';
import { useCsrfToken } from '../../../util/CsrfTokenProvider';

const PendingCourseCard = ({ course, onRemove }) => {
    const [similarCourses, setSimilarCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSliding, setIsSliding] = useState(false);
    const [cardHeight, setCardHeight] = useState('auto');
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const cardRef = useRef(null);
    const contentLoadedRef = useRef(false);
    const { csrfToken } = useCsrfToken();

    // Update card height when similarCourses changes
    useEffect(() => {
        if (!isInitialLoad && cardRef.current) {
            const timeoutId = setTimeout(() => {
                setCardHeight(`${cardRef.current.offsetHeight}px`);
            }, 200);
        }
    }, [similarCourses, isInitialLoad]);

    // Measure the card height on mount and whenever window resizes
    useEffect(() => {
        const updateCardHeight = () => {
            if (cardRef.current) {
                setCardHeight(`${cardRef.current.offsetHeight}px`);
            }
        };

        // Update height after a short delay to ensure the DOM has rendered
        const timeoutId = setTimeout(() => {
            updateCardHeight();
            setIsInitialLoad(false);
        }, 200);

        // Also add resize listener to handle window size changes
        window.addEventListener('resize', updateCardHeight);

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('resize', updateCardHeight);
        };
    }, []);

    useEffect(() => {
        const getSimilarCourses = async () => {
            try {
                const response = await fetchCourseSearch(
                    `${course.course_code} ${course.course_name}`,
                    3,
                );
                const data = await response.json();
                setSimilarCourses(data);
                contentLoadedRef.current = true;

                // Force a height update after content is loaded
                setTimeout(() => {
                    if (cardRef.current) {
                        setCardHeight(`${cardRef.current.offsetHeight}px`);
                    }
                }, 200);
            } catch (error) {
                console.error(error);
                contentLoadedRef.current = true;
            }
        };

        getSimilarCourses();
    }, [course.course_code, course.course_name]);

    const handleSuccessAction = async (action, actionName) => {
        if (isLoading) return;

        setIsLoading(true);

        try {
            const response = await approveCourse(
                course.course_id,
                action,
                csrfToken,
            );

            if (response.status === 200) {
                toast.success(`${actionName} successfully`);

                // Make sure we have the correct height before starting animation
                if (cardRef.current) {
                    setCardHeight(`${cardRef.current.offsetHeight}px`);
                    // Force reflow
                    void cardRef.current.offsetHeight;
                }

                // Then start slide animation
                setIsSliding(true);

                // Wait for animation to complete before notifying parent to remove
                setTimeout(() => {
                    onRemove(course.course_id);
                }, 1000); // Use 1s duration for consistency with other cards
            } else {
                toast.error(`Failed to ${actionName.toLowerCase()}`);
            }
        } catch (error) {
            console.error(error);
            toast.error(`Failed to ${actionName.toLowerCase()}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleApprove = () =>
        handleSuccessAction('approved', 'Course approved');
    const handleReject = () =>
        handleSuccessAction('rejected', 'Course rejected');

    const slideOutStyle = {
        transition:
            'transform 1s ease-out, opacity 1s ease-out, height 1s ease-out, margin 1s ease-out, padding 1s ease-out',
        transform: isSliding ? 'translateX(100%)' : 'translateX(0)',
        opacity: isSliding ? 0 : 1,
        height: isSliding ? '0px' : cardHeight,
        overflow: 'hidden',
        marginTop: isSliding ? '0' : '0em',
        marginBottom: isSliding ? '0' : '1em',
        paddingTop: isSliding ? '0' : undefined,
        paddingBottom: isSliding ? '0' : undefined,
    };

    return (
        <div ref={cardRef}>
            <Card
                fluid
                style={{
                    ...slideOutStyle,
                    border: '1px solid #d4d4d5',
                    boxShadow: 'none',
                }}
            >
                <CardContent>
                    <CardHeader>
                        <CourseTitle course={course} />
                    </CardHeader>
                    <CardContent>{course.num_reviews} reviews</CardContent>
                    {similarCourses.length ? (
                        <Message warning>
                            <Message.Header>Warning</Message.Header>
                            <p>
                                {`There are already courses with similar code/name. Verify below to ensure it is not a duplicate entry:`}
                            </p>
                            {similarCourses &&
                                similarCourses.map((similarCourse) => (
                                    <>
                                        <CourseTitle
                                            key={similarCourse.course_id}
                                            course={similarCourse.course_header}
                                            includeBadge={false}
                                        />
                                        <br />
                                    </>
                                ))}
                        </Message>
                    ) : null}
                </CardContent>
                <ApproveOrRejectFooter
                    handleApprove={handleApprove}
                    handleReject={handleReject}
                    isLoading={isLoading}
                />
            </Card>
        </div>
    );
};

export default PendingCourseCard;

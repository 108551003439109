import React from 'react';

import { Button, CardContent } from 'semantic-ui-react';

const ApproveOrRejectFooter = ({
    handleApprove,
    handleReject,
    isReview = false,
    handleLibel = null,
    handleInsufficient = null,
}) => {
    if (isReview) {
        return (
            <CardContent>
                <Button
                    content="Approve"
                    color="green"
                    onClick={handleApprove}
                />
                <Button
                    content="Insufficient"
                    color="yellow"
                    onClick={handleInsufficient}
                />
                <Button content="Libel" color="red" onClick={handleLibel} />
            </CardContent>
        );
    }

    return (
        <CardContent>
            <Button content="Approve" color="green" onClick={handleApprove} />
            {handleReject && (
                <Button content="Reject" color="red" onClick={handleReject} />
            )}
            {handleInsufficient && (
                <Button
                    content="Insufficient"
                    color="yellow"
                    onClick={handleInsufficient}
                />
            )}
        </CardContent>
    );
};

export default ApproveOrRejectFooter;

import React from 'react';
import LoadingSegment from './LoadingSegment';

const LoadingSegmentPanel = () => (
    <>
        {[...Array(5)].map((_, index) => (
            <LoadingSegment key={index} />
        ))}
    </>
);

export default LoadingSegmentPanel;

import React, { useCallback } from 'react';
import { fetchCoursesMissingCourseCode } from '../../../util/FetchCourseData';
import MissingCourseCodeCard from '../../cards/course-cards/MissingCourseCodeCard';
import GenericPanel from '../GenericPanel';
import { useAdminPanel } from '../useAdminPanel';

const MissingCourseCodePanel = ({
    numberOfCoursesWithMissingCourseCode,
    handleUnauthorized,
    onCourseCodeUpdated,
}) => {
    // Use our custom hook for data fetching and infinite scrolling
    const {
        loading,
        data: coursesWithMissingCourseCode,
        hasMoreItems,
        loadMore,
        removeItem,
    } = useAdminPanel(
        fetchCoursesMissingCourseCode,
        numberOfCoursesWithMissingCourseCode,
        handleUnauthorized,
    );

    // Handle course removal from UI
    const handleRemoveCourse = useCallback(
        (courseId) => {
            removeItem(courseId, 'id');

            // Notify parent component to update the count
            if (onCourseCodeUpdated) {
                onCourseCodeUpdated();
            }
        },
        [removeItem, onCourseCodeUpdated],
    );

    // Render each course card
    const renderCourse = (course) => (
        <MissingCourseCodeCard
            key={course.id}
            course={course}
            onRemove={handleRemoveCourse}
        />
    );

    return (
        <GenericPanel
            loading={loading}
            data={coursesWithMissingCourseCode}
            hasMoreItems={hasMoreItems}
            onLoadMore={loadMore}
            renderItem={renderCourse}
            className="missing-course-code-container"
        />
    );
};

export default MissingCourseCodePanel;

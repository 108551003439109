// src/contexts/CsrfTokenContext.js
import React, { createContext, useState, useContext, useMemo } from 'react';

// Initial context value includes the token and a function to set it
const CsrfTokenContext = createContext({
    csrfToken: null,
    setCsrfToken: () => console.warn('setCsrfToken called outside of Provider'), // Default no-op function
});

// Provider component manages the state
export const CsrfTokenProvider = ({ children }) => {
    const [csrfToken, setCsrfToken] = useState(null);

    // Memoize the context value to prevent unnecessary re-renders of consumers
    // when the provider itself re-renders but the value hasn't changed.
    const contextValue = useMemo(
        () => ({
            csrfToken,
            setCsrfToken, // Pass the actual state setter function
        }),
        [csrfToken],
    ); // Dependency array ensures value updates only when csrfToken changes

    return (
        <CsrfTokenContext.Provider value={contextValue}>
            {children}
        </CsrfTokenContext.Provider>
    );
};

// Custom hook for easy consumption
export const useCsrfToken = () => {
    const context = useContext(CsrfTokenContext);
    if (context === undefined) {
        // This error means you tried to use the context somewhere
        // that isn't wrapped by the CsrfTokenProvider
        throw new Error('useCsrfToken must be used within a CsrfTokenProvider');
    }
    return context; // Returns { csrfToken, setCsrfToken }
};

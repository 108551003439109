import { Image } from 'semantic-ui-react';

import bronzeNugget from '../../icons/nuggets/bronze-nugget.svg';
import goldNugget from '../../icons/nuggets/gold-nugget.svg';
import silverNugget from '../../icons/nuggets/silver-nugget.svg';

export default function Badge({ badgeId, size }) {
    switch (badgeId) {
        case 3: {
            return (
                <Image
                    style={{ width: '1.5em' }}
                    spaced="right"
                    src={goldNugget}
                />
            );
        }
        case 2: {
            return (
                <Image
                    style={{ width: '1.5em' }}
                    spaced="right"
                    src={silverNugget}
                />
            );
        }
        case 1: {
            return (
                <Image
                    style={{ width: '1.5em' }}
                    spaced="right"
                    src={bronzeNugget}
                />
            );
        }
        default: {
            return null;
        }
    }
}

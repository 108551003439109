import React from 'react';

const ProfessorAndCourseLookupRow = ({
    content,
    type,
    setInput,
    setSelected,
    setIsNew,
}) => {
    var text;
    if (type === 'Professor') {
        text = `${content.professor_header.first_name} ${content.professor_header.last_name}`;
    } else if (type === 'Course') {
        text = `[${content.course_header.course_code}] ${content.course_header.course_name}`;
    }

    function handleSelect() {
        setInput(text);
        setIsNew(false);

        if (type === 'Professor') {
            setSelected(content.professor_header.professor_id);
            return;
        } else {
            setSelected(content.course_header.course_id);
            return;
        }
    }

    return <div onClick={handleSelect}>{text}</div>;
};

export default ProfessorAndCourseLookupRow;

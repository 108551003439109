import { useCallback, useEffect, useState } from 'react';

/**
 * Custom hook for admin panels with infinite scrolling
 * @param {Function} fetchFunction - Function to fetch data (should accept page number as parameter)
 * @param {number} totalItems - Total number of items
 * @param {Function} handleUnauthorized - Function to handle unauthorized responses
 * @param {number} itemsPerPage - Number of items per page
 * @returns {Object} - Panel data and methods
 */
export const useAdminPanel = (
    fetchFunction,
    totalItems,
    handleUnauthorized,
    itemsPerPage = 5,
) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [hasMoreItems, setHasMoreItems] = useState(true);

    // Calculate if there are more items to load
    useEffect(() => {
        const totalPages = Math.ceil((totalItems || 0) / itemsPerPage);
        setHasMoreItems(pageNumber < totalPages);
    }, [pageNumber, totalItems, itemsPerPage]);

    // Load more items
    const loadMore = useCallback(() => {
        if (!loading && hasMoreItems) {
            setPageNumber((prev) => prev + 1);
        }
    }, [loading, hasMoreItems]);

    // Reset when dependencies change (except pageNumber)
    const resetData = useCallback(() => {
        setData([]);
        setPageNumber(1);
        setHasMoreItems(true);
    }, []);

    // Fetch data when page number changes
    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await fetchFunction(pageNumber);

                // Stop if the component unmounted during async operation
                if (!isMounted) return;

                if (handleUnauthorized(response)) return;

                if (!response.ok) {
                    throw new Error(
                        `Failed to fetch data: ${response.statusText}`,
                    );
                }

                const responseData = await response.json();

                // Make sure we have an array (even if the API returns null/undefined)
                const newItems = Array.isArray(responseData)
                    ? responseData
                    : [];

                // If it's the first page, replace data, otherwise append
                setData((prevData) =>
                    pageNumber === 1 ? newItems : [...prevData, ...newItems],
                );

                // Calculate if there are more items to load
                const totalPages = Math.ceil((totalItems || 0) / itemsPerPage);
                setHasMoreItems(pageNumber < totalPages);
            } catch (error) {
                console.error(error);
                if (isMounted) {
                    // Don't clear existing data on error, just don't append
                    if (pageNumber === 1) {
                        setData([]);
                    }
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchData();

        // Cleanup function to prevent state updates if component unmounts
        return () => {
            isMounted = false;
        };
    }, [
        pageNumber,
        fetchFunction,
        handleUnauthorized,
        totalItems,
        itemsPerPage,
    ]);

    // Function to remove an item from the data
    const removeItem = useCallback((itemId, idField = 'id') => {
        setData((prevData) =>
            prevData.filter((item) => item[idField] !== itemId),
        );
    }, []);

    return {
        loading,
        data,
        hasMoreItems,
        loadMore,
        removeItem,
        resetData,
    };
};

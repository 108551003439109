import React from 'react';
import Badge from '../Badge';

const ProfessorTitle = ({
    professor,
    includeBadge,
    includeUni = false,
    margin = 10,
}) => {
    const uni = professor.uni || '';
    return (
        <>
            <a
                style={{ 'margin-right': `${margin}px`, color: '#4071CA' }}
                href={`/professor/${professor.professor_id}`}
            >
                {(includeUni ? ` [${uni}] ` : '') +
                    `${professor.first_name} ${professor.last_name}`}
            </a>
            {includeBadge && <Badge badgeId={professor.nugget} />}
        </>
    );
};

export default ProfessorTitle;

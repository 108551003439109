import React, { useState } from 'react';
import { Button, Grid, Image } from 'semantic-ui-react';
import styled from 'styled-components';
import ModernCulpaIcon from '../../icons/modern-culpa.svg';
import MenuButton from './buttons/MenuButton';
import MobileMenu from './MobileMenu';
import SearchBar from './SearchBar';

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 2rem;
    justify-content: space-between;
    gap: 1rem;
`;

const LogoButton = styled(Button)`
    & img {
        width: 50px;
    }
`;

const NavbarGrid = styled(Grid)`
    padding-left: 10% !important;
    padding-right: 10% !important;
    width: 100% !important;
    margin: 0 !important;
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;

    @media (max-width: 768px) {
        padding-left: 2.5% !important;
        padding-right: 2.5% !important;
    }
`;

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => setMenuOpen(!menuOpen);

    return (
        <>
            <NavbarGrid className="culpa-blue">
                <Row
                    style={{
                        'padding-left': '0.5rem',
                        'padding-right': '0.5rem',
                        margin: '0',
                    }}
                >
                    <LogoButton
                        className="culpa-blue"
                        href="/home"
                        style={{
                            'padding-left': '0.5rem',
                            'padding-right': '0.0rem',
                            'padding-top': '0.5rem',
                            'padding-bottom': '0.5rem',
                        }}
                    >
                        <Image src={ModernCulpaIcon} alt="Culpa Icon" />
                    </LogoButton>
                    <SearchBar />
                    <MenuButton onClick={toggleMenu} />
                </Row>
            </NavbarGrid>

            <MobileMenu open={menuOpen} onClose={toggleMenu} />
        </>
    );
};

export default Navbar;

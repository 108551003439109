import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
    Button,
    Form,
    FormField,
    Header,
    Icon,
    Modal,
    Select,
} from 'semantic-ui-react';
import { fetchPresignedUrl } from '../../../util/FetchSyllabusData';
import FileInput from '../FileInput';

const SyllabusUploadModal = ({
    open,
    onClose,
    courses,
    professors,
    onUploadComplete,
}) => {
    const [courseProfessorId, setCourseProfessorId] = useState(null);
    const [presignedUrl, setPresignedUrl] = useState(null);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [fetchingPresigned, setFetchingPresigned] = useState(false);

    useEffect(() => {
        if (open) {
            // Reset state when modal opens
            setCourseProfessorId(null);
            setPresignedUrl(null);
            setSubmitClicked(false);
            setFetchingPresigned(false);
        }
    }, [open]);

    const handleGetPresignedUrl = async () => {
        setFetchingPresigned(true);
        try {
            const response = await fetchPresignedUrl();
            if (!response.ok) {
                throw new Error('Failed to get presigned URL');
            }
            const result = await response.json();
            setPresignedUrl(result);
            setSubmitClicked(true);
        } catch (error) {
            console.error(error);
            toast.error('Failed to upload syllabus. Please try again');
        } finally {
            setFetchingPresigned(false);
        }
    };

    return (
        <Modal onClose={onClose} open={open}>
            <Header icon>
                <Icon name="archive" />
                Submit New Syllabus
            </Header>
            <Modal.Content>
                <Form>
                    {courses && (
                        <FormField
                            control={Select}
                            options={courses.map((course) => ({
                                key: course.course_professor_id,
                                text: `[${course.course_code}] ${course.course_name}`,
                                value: course.course_professor_id,
                            }))}
                            placeholder="Select a Course"
                            onChange={(e, { value }) => {
                                setCourseProfessorId(value);
                            }}
                        />
                    )}
                    {professors && (
                        <FormField
                            control={Select}
                            options={professors.map((professor) => ({
                                key: professor.course_professor_id,
                                text: `${professor.first_name} ${professor.last_name}`,
                                value: professor.course_professor_id,
                            }))}
                            placeholder="Select a Professor"
                            onChange={(e, { value }) =>
                                setCourseProfessorId(value)
                            }
                        />
                    )}
                </Form>
                <p>
                    Please drag your syllabus file below, or click to browse for
                    file
                </p>
                <FileInput
                    submitClicked={submitClicked}
                    setSubmitClicked={setSubmitClicked}
                    presignedUrl={presignedUrl}
                    onUploadComplete={onUploadComplete}
                    courseProfessorId={courseProfessorId}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button basic color="red" onClick={onClose}>
                    <Icon name="remove" /> Cancel
                </Button>
                <Button
                    color="green"
                    loading={fetchingPresigned}
                    onClick={handleGetPresignedUrl}
                >
                    <Icon name="checkmark" /> Submit
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default SyllabusUploadModal;

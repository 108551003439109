import React, { useCallback } from 'react';
import { fetchPendingSyllabi } from '../../../util/FetchSyllabusData';
import PendingSyllabusCard from '../../cards/syllabus-cards/PendingSyllabusCard';
import GenericPanel from '../GenericPanel';
import { useAdminPanel } from '../useAdminPanel';

const PendingSyllabiPanel = ({
    numberOfPendingSyllabi,
    handleUnauthorized,
    onSyllabusStatusUpdated,
}) => {
    // Use our custom hook for data fetching and infinite scrolling
    const {
        loading,
        data: pendingSyllabi,
        hasMoreItems,
        loadMore,
        removeItem,
    } = useAdminPanel(
        fetchPendingSyllabi,
        numberOfPendingSyllabi,
        handleUnauthorized,
    );

    // Handle syllabus removal from UI
    const handleRemoveSyllabus = useCallback(
        (syllabusId) => {
            removeItem(syllabusId, 'syllabus_id');

            // Notify parent component to update the count
            if (onSyllabusStatusUpdated) {
                onSyllabusStatusUpdated();
            }
        },
        [removeItem, onSyllabusStatusUpdated],
    );

    // Render each syllabus card
    const renderSyllabus = (syllabus) => (
        <PendingSyllabusCard
            key={syllabus.syllabus_id}
            syllabus={syllabus}
            onRemove={handleRemoveSyllabus}
        />
    );

    return (
        <GenericPanel
            loading={loading}
            data={pendingSyllabi}
            hasMoreItems={hasMoreItems}
            onLoadMore={loadMore}
            renderItem={renderSyllabus}
            className="pending-syllabi-container"
        />
    );
};

export default PendingSyllabiPanel;

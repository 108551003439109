import getCookie from './FetchAuthentication';
import { fetchWithRateLimitCheck } from './api';
import { useCsrfToken } from './CsrfTokenProvider';

const api_url = process.env.REACT_APP_GATEWAY_URL;

// Helper function to create URLs with query parameters
const createUrl = (endpoint, params = {}) => {
    const queryString = new URLSearchParams(params).toString();
    return `${api_url}${endpoint}${queryString ? `?${queryString}` : ''}`;
};

// This file contains functions that fetch data from the backend related to professors
export function fetchProfessorSummaries(page) {
    return fetchWithRateLimitCheck(
        createUrl('api/professor/summary/all', { page: page }),
        {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        },
    );
}

export function fetchProfessorSummary(professor_id) {
    return fetchWithRateLimitCheck(
        `${api_url}api/professor/summary/${professor_id}`,
        {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        },
    );
}

export function fetchAllProfessorsForCourse(course_id) {
    return fetchWithRateLimitCheck(
        `${api_url}api/professor/taught/${course_id}`,
        {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        },
    );
}

export function fetchPendingProfessors(page) {
    return fetchWithRateLimitCheck(
        createUrl('api/professor/pending', { page: page }),
        {
            method: 'GET',
            headers: {
                'X-CSRF-TOKEN': getCookie('csrf_access_token'),
            },
            credentials: 'include',
        },
    );
}

export function fetchProfessorsWithMissingUni(page) {
    return fetchWithRateLimitCheck(
        createUrl('api/professor/missing_uni', { page: page }),
        {
            method: 'GET',
            headers: {
                'X-CSRF-TOKEN': getCookie('csrf_access_token'),
            },
            credentials: 'include',
        },
    );
}

export function fetchProfessorSearch(search_query, maxResults = 10) {
    return fetchWithRateLimitCheck(
        createUrl('api/professor/search', {
            queryString: search_query,
            maxResults: maxResults,
        }),
        {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        },
    );
}

export function approveProfessor(professorId, newStatus, csrf_token) {
    return fetchWithRateLimitCheck(`${api_url}api/professor/approve`, {
        method: 'PUT',
        headers: {
            // Use the token read *after* the delay
            'X-CSRF-TOKEN': csrf_token,
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            professor_id: professorId,
            new_status: newStatus,
        }),
    });
}

// Remember your getCookie function needs to be defined elsewhere
// function getCookie(name) { ... }

export function fetchProfessorRatingsHistogram(professorId) {
    return fetchWithRateLimitCheck(
        `${api_url}api/professor/ratings/${professorId}`,
        {
            method: 'GET',
        },
    );
}

export function updateProfessorUni(professorId, uni, csrfToken) {
    return fetchWithRateLimitCheck(`${api_url}api/professor/uni`, {
        method: 'PUT',
        headers: {
            'X-CSRF-TOKEN': csrfToken,
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ professor_id: professorId, uni: uni }),
    });
}

export function fetchProfessorCardData(professor_id) {
    return fetchWithRateLimitCheck(
        `${api_url}api/professor_page/card/${professor_id}`,
        {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        },
    );
}

import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardContent, CardHeader, Form } from 'semantic-ui-react';
import { updateCourseCode } from '../../../util/FetchCourseData';
import ApproveOrRejectFooter from '../../admin/ApproveOrRejectFooter';
import CourseTitle from '../../common/titles/CourseTitle';
import { useCsrfToken } from '../../../util/CsrfTokenProvider';

const MissingCourseCodeCard = ({ course, onRemove }) => {
    const [isSliding, setIsSliding] = useState(false);
    const [courseCode, setCourseCode] = useState('');
    const [cardHeight, setCardHeight] = useState('auto');
    const cardRef = useRef(null);
    const { csrfToken } = useCsrfToken();

    // Measure the card height on mount
    useEffect(() => {
        if (cardRef.current) {
            setCardHeight(`${cardRef.current.offsetHeight}px`);
        }
    }, []);

    const handleSuccessAction = async () => {
        try {
            let response = await updateCourseCode(
                course.course_id,
                courseCode,
                csrfToken,
            );

            if (response.ok || response.status === 200) {
                toast.success(`Added course code successfully`);

                // First set explicit height
                if (cardRef.current) {
                    setCardHeight(`${cardRef.current.offsetHeight}px`);
                    // Force reflow
                    void cardRef.current.offsetHeight;
                }

                // Then start slide animation
                setIsSliding(true);

                // Wait for animation to complete before notifying parent to remove
                setTimeout(() => {
                    onRemove(course.course_id);
                }, 1000); // Match this with CSS animation duration
            } else {
                toast.error(`Failed to add course code`);
            }
        } catch (error) {
            console.error(error);
            toast.error(`Failed to add course code`);
        }
    };

    const handleApprove = () => handleSuccessAction();

    const slideOutStyle = {
        transition:
            'transform 1s ease-out, opacity 1s ease-out, height 1s ease-out, margin 1s ease-out, padding 1s ease-out',
        transform: isSliding ? 'translateX(100%)' : 'translateX(0)',
        opacity: isSliding ? 0 : 1,
        height: isSliding ? '0px' : cardHeight,
        overflow: 'hidden',
        marginTop: isSliding ? '0' : '0em',
        marginBottom: isSliding ? '0' : '1em',
        paddingTop: isSliding ? '0' : undefined,
        paddingBottom: isSliding ? '0' : undefined,
    };

    return (
        <div ref={cardRef}>
            <Card
                fluid
                style={{
                    ...slideOutStyle,
                    border: '1px solid #d4d4d5',
                    boxShadow: 'none',
                }}
            >
                <CardContent>
                    <CardHeader>
                        <CourseTitle course={course} />
                    </CardHeader>
                    <CardContent>{course.num_reviews} reviews</CardContent>
                    
                    <br />
                    <Form size="large">
                        <Form.Input
                            inline="true"
                            icon="id card"
                            iconPosition="left"
                            placeholder="Course Code"
                            value={courseCode}
                            onChange={(e) => setCourseCode(e.target.value)}
                        />
                    </Form>
                </CardContent>
                <ApproveOrRejectFooter handleApprove={handleApprove} />
            </Card>
        </div>
    );
};

export default MissingCourseCodeCard;

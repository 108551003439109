import React from 'react';
import { Message } from 'semantic-ui-react';

const NoActionItemsCard = () => {
    return (
        <Message success>
            <Message.Header>All Done</Message.Header>
            <p>{`There are no actions item in this category, good job!`}</p>
        </Message>
    );
};

export default NoActionItemsCard;

import React from 'react';
import { Button } from 'semantic-ui-react';
import NoActionItemsCard from '../cards/status-cards/NoActionItemsCard';
import LoadingSegmentPanel from '../common/loading/LoadingSegmentPanel';

/**
 * Generic panel component that handles loading states and infinite scrolling
 * @param {boolean} loading - Whether data is currently loading
 * @param {Array} data - The items to display
 * @param {boolean} hasMoreItems - Whether there are more items to load
 * @param {Function} onLoadMore - Function to load more items
 * @param {Function} renderItem - Function to render each item
 * @param {Component} EmptyComponent - Component to render when no items are available
 * @param {string} className - CSS class name for the container
 */
const GenericPanel = ({
    loading,
    data,
    hasMoreItems,
    onLoadMore,
    renderItem,
    EmptyComponent = NoActionItemsCard,
    className = '',
}) => {
    // Show loading state when initially loading with no data
    if (loading && (!data || data.length === 0)) {
        return <LoadingSegmentPanel />;
    }

    // Show empty state when no data is available and not loading
    if (!loading && (!data || data.length === 0)) {
        return <EmptyComponent />;
    }

    return (
        <>
            <div className={className}>
                {data.map((item, index) => renderItem(item, index))}
            </div>

            {/* Load More Button */}
            {hasMoreItems && (
                <div style={{ textAlign: 'center', margin: '2rem 0' }}>
                    <Button
                        className="load-more-button"
                        onClick={onLoadMore}
                        loading={loading}
                        disabled={loading}
                    >
                        Load More
                    </Button>
                </div>
            )}

            {/* No more items message */}
            {!hasMoreItems && data.length > 0 && (
                <div style={{ textAlign: 'center', margin: '2rem 0' }}>
                    <p>No more items to load</p>
                </div>
            )}
        </>
    );
};

export default GenericPanel;

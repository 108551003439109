import React, { useCallback } from 'react';
import { fetchProfessorsWithMissingUni } from '../../../util/FetchProfessorData';
import ProfessorWithMissingUniCard from '../../cards/professor-cards/ProfessorWithMissingUniCard';
import GenericPanel from '../GenericPanel';
import { useAdminPanel } from '../useAdminPanel';

const ProfessorWithMissingUniPanel = ({
    numberOfProfessorsWithMissingUni,
    handleUnauthorized,
    onProfessorUniUpdated,
}) => {
    // Use our custom hook for data fetching and infinite scrolling
    const {
        loading,
        data: professorsWithMissingUni,
        hasMoreItems,
        loadMore,
        removeItem,
    } = useAdminPanel(
        fetchProfessorsWithMissingUni,
        numberOfProfessorsWithMissingUni,
        handleUnauthorized,
    );

    // Handle professor removal from UI
    const handleRemoveProfessor = useCallback(
        (professorId) => {
            removeItem(professorId, 'professor_id');

            // Notify parent component to update the count
            if (onProfessorUniUpdated) {
                onProfessorUniUpdated();
            }
        },
        [removeItem, onProfessorUniUpdated],
    );

    // Render each professor card
    const renderProfessor = (professor) => (
        <ProfessorWithMissingUniCard
            key={professor.professor_id}
            professor={professor}
            onRemove={handleRemoveProfessor}
        />
    );

    return (
        <GenericPanel
            loading={loading}
            data={professorsWithMissingUni}
            hasMoreItems={hasMoreItems}
            onLoadMore={loadMore}
            renderItem={renderProfessor}
            className="missing-uni-container"
        />
    );
};

export default ProfessorWithMissingUniPanel;

import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { Container, Message } from 'semantic-ui-react';
import {
    confirmSyllabusUpload,
    uploadSyllabus,
} from '../../util/FetchSyllabusData';

const FileInput = ({
    submitClicked,
    setSubmitClicked,
    presignedUrl,
    onUploadComplete,
    courseProfessorId,
}) => {
    const [fileTooLarge, setFileTooLarge] = useState(false);
    const [tooManyFiles, setTooManyFiles] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: { 'application/pdf': ['.pdf'] },
        maxSize: 10485760, // 10MB
        maxFiles: 1,
        validator: (file) => {
            if (!file.name.toLowerCase().endsWith('.pdf')) {
                return {
                    code: 'invalid-file-type',
                    message: 'Only PDF files are allowed',
                };
            }
        },
    });

    useEffect(() => {
        let isMounted = true;

        const handleSubmit = async () => {
            if (
                !isUploading &&
                submitClicked &&
                presignedUrl &&
                acceptedFiles[0] &&
                courseProfessorId
            ) {
                setIsUploading(true);
                try {
                    const response = await uploadSyllabus(
                        acceptedFiles[0],
                        presignedUrl.presigned_url,
                    );
                    if (!response.ok) throw new Error('Upload failed');

                    const confirmation = await confirmSyllabusUpload(
                        presignedUrl.file_name,
                        courseProfessorId,
                    );
                    if (!confirmation.ok)
                        throw new Error('Confirmation failed');

                    toast.success('Syllabus uploaded successfully');
                    onUploadComplete();
                } catch (error) {
                    console.error(error);
                    toast.error('Failed to upload syllabus. Please try again');
                } finally {
                    if (isMounted) {
                        setSubmitClicked(false);
                        setIsUploading(false);
                    }
                }
            }
        };

        handleSubmit();

        return () => {
            isMounted = false;
        };
    }, [
        submitClicked,
        presignedUrl,
        acceptedFiles,
        onUploadComplete,
        setSubmitClicked,
        isUploading,
    ]);

    useEffect(() => {
        if (fileRejections.length > 0) {
            const errors = fileRejections.flatMap(({ errors }) =>
                errors.map(({ code }) => code),
            );
            setFileTooLarge(errors.includes('file-too-large'));
            setTooManyFiles(errors.includes('too-many-files'));
        } else {
            setFileTooLarge(false);
            setTooManyFiles(false);
        }
    }, [fileRejections]);

    return (
        <Container
            className="file-input-container cursor-pointer"
            {...getRootProps({ isFocused, isDragAccept, isDragReject })}
        >
            <input {...getInputProps()} />
            <p>Select your syllabus file</p>
            <p>Only PDF files are accepted</p>
            <p>Maximum file size: 10MB</p>

            {acceptedFiles.length > 0 && (
                <div>
                    <strong>Selected file:</strong>
                    {acceptedFiles.map((file) => (
                        <div key={file.path}>{file.path}</div>
                    ))}
                </div>
            )}

            {fileTooLarge && (
                <Message negative>
                    <Message.Header>File Too Large</Message.Header>
                    <p>Please select a file smaller than 10MB</p>
                </Message>
            )}

            {tooManyFiles && (
                <Message negative>
                    <Message.Header>Too Many Files</Message.Header>
                    <p>Please select only one file</p>
                </Message>
            )}
        </Container>
    );
};

export default FileInput;

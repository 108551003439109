import React, { useEffect, useState } from 'react';
import {
    Button,
    Grid,
    Icon,
    Label,
    Menu,
    Segment,
    Sidebar,
} from 'semantic-ui-react';

/**
 * Responsive layout for admin pages with desktop and mobile views
 * @param {Array} menuItems - Array of menu items to display
 * @param {string} selectedItem - Currently selected menu item ID
 * @param {Function} onSelectItem - Function to call when a menu item is selected
 * @param {ReactNode} children - Content to display in the main area
 */
const AdminLayout = ({ menuItems, selectedItem, onSelectItem, children }) => {
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

    // Handle responsive layout
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Handle menu selection (mobile)
    const handleMenuSelect = (id) => {
        onSelectItem(id);
        setSidebarVisible(false);
    };

    // Sticky menu style for desktop
    const sidebarMenuStyle = {
        position: 'sticky',
        top: '20px',
        maxHeight: 'calc(100vh - 120px)',
        overflowY: 'auto',
    };

    // Render desktop layout
    if (!isMobile) {
        return (
            <Grid columns={2}>
                <Grid.Column className="menu-column" width={5}>
                    <div style={sidebarMenuStyle}>
                        <Menu vertical fluid>
                            {menuItems.map((item) => (
                                <Menu.Item
                                    key={item.id}
                                    active={selectedItem === item.id}
                                    onClick={() => onSelectItem(item.id)}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <span>{item.title}</span>
                                        {item.count != null && (
                                            <Label>{item.count}</Label>
                                        )}
                                    </div>
                                </Menu.Item>
                            ))}
                        </Menu>
                    </div>
                </Grid.Column>
                <Grid.Column width={11}>{children}</Grid.Column>
            </Grid>
        );
    }

    // Render mobile layout
    return (
        <>
            <Button
                icon
                onClick={() => setSidebarVisible(true)}
                style={{ marginBottom: '1em' }}
            >
                <Icon name="bars" />
            </Button>
            <Sidebar.Pushable
                as={Segment}
                style={{
                    border: 'none',
                    boxShadow: 'none',
                    marginTop: '1em',
                    minHeight: '100vh',
                }}
            >
                <Sidebar
                    as={Menu}
                    animation="overlay"
                    direction="left"
                    onHide={() => setSidebarVisible(false)}
                    vertical
                    visible={sidebarVisible}
                    width="wide"
                >
                    {menuItems.map((item) => (
                        <Menu.Item
                            key={item.id}
                            active={selectedItem === item.id}
                            onClick={() => handleMenuSelect(item.id)}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <span>{item.title}</span>
                                <span>{item.count}</span>
                            </div>
                        </Menu.Item>
                    ))}
                </Sidebar>
                <Sidebar.Pusher>{children}</Sidebar.Pusher>
            </Sidebar.Pushable>
        </>
    );
};

export default AdminLayout;

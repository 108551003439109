import React from 'react';
import { Container } from 'semantic-ui-react';
import styled from 'styled-components';
import Footer from '../components/common/Footer';
import Navbar from '../components/common/Navbar';

const ContentWrapper = styled.div`
    padding-top: 4em;
    padding-left: 25%;
    padding-right: 25%;
    flex: 1;

    @media (max-width: 768px) {
        padding-left: 5%;
        padding-right: 5%;
    }
`;

const FlexContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const PolicySection = styled.div`
    margin-bottom: 2em;
`;

const PolicyText = styled.p`
    font-size: 1.1em;
    font-weight: 400;
    margin-bottom: 1em;
`;

const ListItem = styled.li`
    font-size: 1.1em;
    font-weight: 400;
    margin-bottom: 0.5em;
`;

const PrivacyPolicyPage = () => (
    <>
        <Navbar />
        <FlexContainer style={{ width: '100vw', paddingBottom: '5em' }}>
            <ContentWrapper>
                <h1>PRIVACY POLICY</h1>
                <PolicyText>
                    This is the privacy policy ("Privacy Policy") describing our
                    privacy practices for the CULPA.info website, applications
                    and other interactive services ("Site," "we," "us," or
                    "our"). The Site is owned, operated and/or provided by
                    Spectator Publishing Company ("Company", "we," "us," or
                    "our").
                </PolicyText>

                <PolicySection>
                    <h3>This Privacy Policy applies:</h3>
                    <ul>
                        <ListItem>
                            Every time you access the Site, regardless of
                            device;
                        </ListItem>
                        <ListItem>
                            Whether you are accessing the Site as a registered
                            user (if offered by the Site) or other user of the
                            Site;
                        </ListItem>
                        <ListItem>
                            To all information collected by the Site;
                        </ListItem>
                        <ListItem>
                            To our use of combined information if we combine
                            Information collected by the Site with other
                            information we collect from other sources, such as
                            information received from the Company (for example,
                            information collected at a physical location),
                            marketing companies or Advertisers; and
                        </ListItem>
                        <ListItem>
                            Will remain in full force and effect even if your
                            use of or participation in the Site or any
                            particular service, feature, function or promotional
                            activity offered through the Site, you consent to
                            our privacy practices as described in this Privacy
                            Policy.
                        </ListItem>
                    </ul>
                </PolicySection>

                <PolicySection>
                    <h3>What information do we collect?</h3>
                    <PolicyText>
                        We collect information from you when you submit content
                        to our site, for example when you submit User
                        Contributions to the Site and we store that content in
                        our database and share it with other users of the Site.
                    </PolicyText>
                    <PolicyText>
                        The Company is in no way responsible for the accuracy,
                        use, or misuse of any information, including personal
                        information, that you disclose or receive through the
                        Site. If you post any User Contribution to the Site,
                        please remember that any information included in such
                        User Contributions is automatically made public and
                        please consider when disclosing any personal information
                        in your User Contributions that you do not know who may
                        access and use user contribution or for what purpose.
                    </PolicyText>
                    <PolicyText>
                        We ask for your email address to use the Google Forms
                        service to receive comments on the Site.
                    </PolicyText>
                    <PolicyText>
                        We also collect users' IP addresses (anonymously, and
                        used in aggregated databases) for analytics purposes.
                    </PolicyText>
                </PolicySection>

                <PolicySection>
                    <h3>What do we use your information for?</h3>
                    <PolicyText>
                        Any information we collect from you may be used in one
                        of the following ways:
                    </PolicyText>
                    <ul>
                        <ListItem>
                            To improve the Site (we continually strive to
                            improve our website offerings based on the
                            information and feedback we receive from you), and
                            other websites and products of the Company;
                        </ListItem>
                        <ListItem>
                            To improve customer service (your information helps
                            us to more effectively respond to your customer
                            service requests and support needs); and
                        </ListItem>
                        <ListItem>
                            To process transactions that you have specifically
                            requested.
                        </ListItem>
                    </ul>
                    <PolicyText>
                        Your information, whether public or private, will not be
                        sold, exchanged, transferred, or given to any other
                        company for any reason whatsoever, without your consent,
                        other than for the express purpose of delivering the
                        purchased product or service requested (if any).
                    </PolicyText>
                </PolicySection>

                <PolicySection>
                    <h3>Do we use cookies?</h3>
                    <PolicyText>Yes</PolicyText>
                </PolicySection>

                <PolicySection>
                    <h3>Do we disclose any information to outside parties?</h3>
                    <PolicyText>
                        We do not sell, trade, or otherwise transfer to outside
                        parties your personally identifiable information other
                        than for the express purpose of delivering the purchased
                        product or service requested. This does not include
                        trusted third parties who assist us in operating our
                        website, conducting our business, or servicing you, so
                        long as those parties agree to keep this information
                        confidential. We may also release your information when
                        we believe release is appropriate to comply with the
                        law, enforce our site policies, or protect ours or
                        others rights, property, or safety.
                    </PolicyText>
                </PolicySection>

                <PolicySection>
                    <h3>Third-Party Links</h3>
                    <PolicyText>
                        Occasionally, at our discretion, we may include or offer
                        third party products or services on our website. These
                        third party sites have separate and independent privacy
                        policies. We therefore have no responsibility or
                        liability for the content and activities of these linked
                        sites. Nonetheless, we seek to protect the integrity of
                        our site and welcome any feedback about these sites.
                    </PolicyText>
                </PolicySection>

                <PolicySection>
                    <h3>California Online Privacy Protection Act Compliance</h3>
                    <PolicyText>
                        Because we value your privacy we have taken the
                        necessary precautions to be in compliance with the
                        California Online Privacy Protection Act. We therefore
                        will not distribute your personal information to outside
                        parties without your consent except as permitted by this
                        policy or by law.
                    </PolicyText>
                </PolicySection>

                <PolicySection>
                    <h3>Age Requirement to Use the Site</h3>
                    <PolicyText>
                        You must be at least 17 years of age to use the Site.
                    </PolicyText>
                </PolicySection>

                <PolicySection>
                    <h3>Online Privacy Policy Only</h3>
                    <PolicyText>
                        This online privacy policy applies only to information
                        collected through our website and not to information
                        collected offline.
                    </PolicyText>
                </PolicySection>

                <PolicySection>
                    <h3>Your Consent</h3>
                    <PolicyText>
                        By using our site, you consent to this privacy policy.
                    </PolicyText>
                </PolicySection>

                <PolicySection>
                    <h3>U.S. Governing Law</h3>
                    <PolicyText>
                        The Site is designed and targeted to U.S. audiences and
                        is governed by and operated in accordance with the laws
                        of the U.S. We make no representation that this Site is
                        operated in accordance with the laws or regulations of,
                        or governed by, other nations. If you are located
                        outside of the U.S., you use this Site at your own risk
                        and initiative and you, not us, are responsible for
                        compliance with any applicable local and national laws.
                        Please be aware that any Personal Information and Other
                        Information you provide to us or we obtain as a result
                        of your use of this Site shall be collected in the U.S.
                        and/or transferred to the U.S. and subject to U.S. law.
                        By using this Site, participating in any Site activities
                        and/or providing us with your Personal Information and
                        Other Information, you (a) consent to the transfer
                        and/or processing of any Information to and in the U.S.,
                        (b) acknowledge that U.S. law may provide a lower
                        standard of protection for personal data than the laws
                        of your location and (c) understand that we shall
                        collect, transfer, store, process and/or deal with your
                        Information in accordance with this Privacy Policy and
                        U.S. law. Consequently, to the full extent permitted by
                        law, you hereby waive any claims relating to the
                        processing of your Personal Information or Other
                        Information in accordance with this Privacy Policy that
                        may arise under the laws and regulations that apply to
                        you in or of any other country or jurisdiction.
                    </PolicyText>
                </PolicySection>

                <PolicySection>
                    <h3>Changes to our Privacy Policy</h3>
                    <PolicyText>
                        We reserve the right to revise this Privacy Policy at
                        any time for any reason in our sole discretion by
                        posting an updated Privacy Policy without advance notice
                        to you. Such revisions shall be effective immediately
                        upon posting and if you use the Site after they become
                        effective it will signify your agreement to be bound by
                        the changes. We shall post or display notices of
                        material changes on the Site's homepage and/or otherwise
                        on the Site and/or e-mail you or notify you upon login
                        about these changes; the form of such notice is at our
                        discretion. However, we encourage you to check this
                        Privacy Policy and Terms of Use Agreement often for
                        updates.
                    </PolicyText>
                </PolicySection>

                <PolicyText>
                    <strong>
                        This policy was last modified on March 9th, 2025.
                    </strong>
                </PolicyText>
            </ContentWrapper>
        </FlexContainer>
        <Footer />
    </>
);

export default PrivacyPolicyPage;

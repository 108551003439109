import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, CardContent, Grid, Header } from 'semantic-ui-react';
import '../../../App.css';
import { fetchCoursePageData } from '../../../util/FetchCourseData';
import LoaderAnimation from '../../common/LoaderAnimation';
import SyllabusUploadModal from '../../common/modal/SyllabusUploadModal';
import CourseSyllabi from './CourseSyllabi';

const CourseCard = ({ course_id, professors, setProfessors }) => {
    const [syllabusModalOpen, setSyllabusModalOpen] = useState(false);
    const [cardDataLoading, setCardDataLoading] = useState(true);
    const [cardData, setCardData] = useState(null);
    const navigate = useNavigate();

    const handleUploadComplete = () => {
        setSyllabusModalOpen(false);
    };

    useEffect(() => {
        const getCoursePageData = async () => {
            try {
                setCardDataLoading(true);
                const response = await fetchCoursePageData(course_id);
                const result = await response.json();
                setCardData(result);
                setProfessors(result.professors_that_taught);
            } catch (error) {
                console.error(error);
            } finally {
                setCardDataLoading(false);
            }
        };

        getCoursePageData();
    }, [course_id, setProfessors]);

    const handleReviewClick = () => {
        if (
            cardData &&
            cardData.course_summary &&
            cardData.course_summary.course_header
        ) {
            const courseInfo = {
                course_id: cardData.course_summary.course_header.course_id,
                course_name: cardData.course_summary.course_header.course_name,
                course_code: cardData.course_summary.course_header.course_code,
                search_result_type: 'Course',
            };

            navigate('/review/new', { state: { courseData: courseInfo } });
        }
    };

    if (cardDataLoading) {
        return (
            <Card fluid>
                <CardContent>
                    <LoaderAnimation />
                </CardContent>
            </Card>
        );
    }

    return (
        <>
            <Card fluid>
                <CardContent>
                    <Header as="h1">
                        {`[${cardData.course_summary.course_header.course_code}] 
                    ${cardData.course_summary.course_header.course_name}`}
                    </Header>
                </CardContent>
                <CardContent>
                    <Grid columns={2} stackable>
                        <Grid.Row>
                            <Grid.Column>
                                <Header as="h2" content="Professors" />
                                {professors.map((professor) => {
                                    return (
                                        <p
                                            key={professor.professor_id}
                                            className="no-margin-bottom"
                                        >
                                            <Link
                                                to={`/professor/${professor.professor_id}`}
                                            >
                                                {`${professor.first_name} ${professor.last_name}`}
                                            </Link>
                                        </p>
                                    );
                                })}
                                <br />
                                <Button
                                    className="orange-button"
                                    onClick={handleReviewClick}
                                >
                                    {`WRITE A REVIEW FOR ${cardData.course_summary.course_header.course_code}`}
                                </Button>
                                <Header as="h2" content="Syllabi" />
                                <CourseSyllabi syllabi={cardData.syllabi} />
                                <br />
                                <Button
                                    className="blue-button"
                                    onClick={() => setSyllabusModalOpen(true)}
                                >
                                    Submit a Syllabus
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </CardContent>
            </Card>

            <SyllabusUploadModal
                open={syllabusModalOpen}
                onClose={() => setSyllabusModalOpen(false)}
                professors={professors}
                onUploadComplete={handleUploadComplete}
            />
        </>
    );
};

export default CourseCard;

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CardContent } from 'semantic-ui-react';
import styled from 'styled-components';
import getCookie from '../../../util/FetchAuthentication';
import {
    deleteVote,
    postNewVote,
    updateVote,
} from '../../../util/FetchVoteData';

// Import SVG icons - update the paths as needed
import { ReactComponent as AgreeFilledIcon } from '../../../icons/votes/agree-icon-filledd.svg';
import { ReactComponent as AgreeIcon } from '../../../icons/votes/like-icon.svg';
import { ReactComponent as DisagreeFilledIcon } from '../../../icons/votes/disagree-icon-filledd.svg';
import { ReactComponent as DisagreeIcon } from '../../../icons/votes/dislike-icon.svg';
import { ReactComponent as FunnyFilledIcon } from '../../../icons/votes/funny-icon-filled.svg';
import { ReactComponent as FunnyIcon } from '../../../icons/votes/funny-icon.svg';

// Responsive container for reactions that changes layout based on screen size
const ReactionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;

    @media (max-width: 991px) {
        flex-direction: row;
        justify-content: center;
    }
`;

const IconContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding-right: 0px;
`;

const IconWrapper = styled.div`
    position: relative;
    width: 32px;
    height: 32px;
    margin-bottom: 5px;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.1);
    }

    svg {
        width: 100%;
        height: 100%;
        transition: opacity 0.5s ease;
        position: absolute;
        top: 0;
        left: 0;
    }
`;

const VoteCount = styled.span`
    font-size: 14px;
    font-weight: 600;
    color: white;
`;

const ReactionIcon = ({ type, label, color, isActive, count, onClick }) => {
    // Determine which icons to use based on type
    let DefaultIcon, FilledIcon;

    switch (type) {
        case 'agree':
            DefaultIcon = AgreeIcon;
            FilledIcon = AgreeFilledIcon;
            break;
        case 'disagree':
            DefaultIcon = DisagreeIcon;
            FilledIcon = DisagreeFilledIcon;
            break;
        case 'funny':
            DefaultIcon = FunnyIcon;
            FilledIcon = FunnyFilledIcon;
            break;
        default:
            DefaultIcon = AgreeIcon;
            FilledIcon = AgreeFilledIcon;
    }

    return (
        <IconContainer onClick={onClick}>
            <IconWrapper>
                <DefaultIcon style={{ opacity: isActive ? 0 : 1 }} />
                <FilledIcon style={{ opacity: isActive ? 1 : 0 }} />
            </IconWrapper>
            <VoteCount>{count}</VoteCount>
        </IconContainer>
    );
};

const ReviewFooter = ({
    review_id,
    agree_count,
    disagree_count,
    funny_count,
}) => {
    const [voteStatus, setVoteStatus] = useState(null);

    useEffect(() => {
        const vote_cookie = getCookie(`review_${review_id}_vote`);
        if (vote_cookie) {
            setVoteStatus(vote_cookie);
        }
    }, [review_id]);

    const handleReaction = async (type) => {
        const prevType = voteStatus;

        // If already voted for this type, remove vote
        if (voteStatus === type) {
            try {
                const response = await deleteVote(review_id, type);
                if (!response.ok) {
                    toast.error('Failed to remove your vote, please try again');
                } else {
                    setVoteStatus(null);
                    document.cookie = `review_${review_id}_vote=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
                }
            } catch (error) {
                toast.error('Failed to remove your vote, please try again');
            }
        }
        // If voted for a different type, change vote
        else if (voteStatus) {
            try {
                const response = await updateVote(review_id, prevType, type);
                if (!response.ok) {
                    toast.error('Failed to change your vote, please try again');
                } else {
                    setVoteStatus(type);
                    document.cookie = `review_${review_id}_vote=${type}; path=/; max-age=31536000`;
                }
            } catch (error) {
                toast.error('Failed to change your vote, please try again');
            }
        }
        // If no previous vote, add new vote
        else {
            try {
                const response = await postNewVote(review_id, type);
                if (!response.ok) {
                    toast.error('Failed to cast your vote, please try again');
                } else {
                    setVoteStatus(type);
                    document.cookie = `review_${review_id}_vote=${type}; path=/; max-age=31536000`;
                }
            } catch (error) {
                toast.error('Failed to cast your vote, please try again');
            }
        }
    };

    return (
        <CardContent style={{ border: 'none' }}>
            <ReactionContainer>
                <ReactionIcon
                    type="agree"
                    label="Agree"
                    color="#21ba45"
                    isActive={voteStatus === 'agree'}
                    count={agree_count + (voteStatus === 'agree')}
                    onClick={() => handleReaction('agree')}
                />
                <ReactionIcon
                    type="disagree"
                    label="Disagree"
                    color="#db2828"
                    isActive={voteStatus === 'disagree'}
                    count={disagree_count + (voteStatus === 'disagree')}
                    onClick={() => handleReaction('disagree')}
                />
                <ReactionIcon
                    type="funny"
                    label="Funny"
                    color="#FDD835"
                    isActive={voteStatus === 'funny'}
                    count={funny_count + (voteStatus === 'funny')}
                    onClick={() => handleReaction('funny')}
                />
            </ReactionContainer>
        </CardContent>
    );
};

export default ReviewFooter;

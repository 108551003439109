import { fetchWithRateLimitCheck } from './api';

const api_url = process.env.REACT_APP_GATEWAY_URL;

// Helper function to create URLs with query parameters
const createUrl = (endpoint, params = {}) => {
    const queryString = new URLSearchParams(params).toString();
    return `${api_url}${endpoint}${queryString ? `?${queryString}` : ''}`;
};

export function fetchProfessorAndCourseSearch(query, signal) {
    return fetchWithRateLimitCheck(
        createUrl('api/search/search', { queryString: query }),
        {
            signal,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        },
    );
}

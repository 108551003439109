import React from 'react';

const NewCourseRow = ({ setIsNewCourse, setInput, setNewCourse }) => {
    return (
        <div
            onClick={() => {
                setIsNewCourse(true);
                setInput('New Course');
                setNewCourse({});
            }}
        >
            <strong>{"Can't find the Course? Add a new Course"}</strong>
        </div>
    );
};

export default NewCourseRow;

import React from 'react';
import { Container } from 'semantic-ui-react';
import styled from 'styled-components';
import Footer from '../components/common/Footer';
import Navbar from '../components/common/Navbar';

const ContentWrapper = styled.div`
    padding-top: 4em;
    padding-left: 25%;
    padding-right: 25%;
    flex: 1;

    @media (max-width: 768px) {
        padding-left: 5%;
        padding-right: 5%;
    }
`;

const FlexContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const JoinTheTeamPage = () => {
    return (
        <>
            <Navbar />
            <FlexContainer style={{ width: '100vw', paddingBottom: '5em' }}>
                <ContentWrapper>
                    <h1>Join the Team</h1>
                    <p style={{ fontSize: '1.2em', fontWeight: '400' }}>
                        CULPA is an entirely student-run website owned by the
                        Spectator Publishing Company and operated by the{' '}
                        <a href="https://www.specpublishing.com/4-apply-now">
                            Product and Engineering teams at Spectator
                        </a>
                        . Have ideas about improving CULPA? Interested in
                        web-development or database work? Want to become a
                        moderator? See below on how to join!
                    </p>

                    <h3>Administrator</h3>
                    <p style={{ fontSize: '1.2em', fontWeight: '400' }}>
                        CULPA administrators run the week-to-week operations of
                        CULPA, review its governing policies, handle disputes,
                        and are responsible for the public-facing
                        communications/marketing of the service. Interested in
                        becoming a CULPA Admin?{' '}
                        <a href="https://www.specpublishing.com/4-apply-now">
                            Join the Product team at Spectator
                        </a>
                        . Applications open at the beginning of each semester.
                    </p>

                    <h3>Designer</h3>
                    <p style={{ fontSize: '1.2em', fontWeight: '400' }}>
                        Our design team is responsible for improving CULPA as a
                        platform. Designers create the UI/UX interface of the
                        website, ideate and implement new high-impact features,
                        and conduct market-research to make decisions about the
                        future direction of CULPA. Our design team works in
                        Figma. Interested in becoming a designer for CULPA?{' '}
                        <a href="https://www.specpublishing.com/4-apply-now">
                            Join the Product team at Spectator
                        </a>
                        . Applications open at the beginning of each semester.
                    </p>

                    <h3>Developer</h3>
                    <p style={{ fontSize: '1.2em', fontWeight: '400' }}>
                        CULPA developers maintain CULPA’s codebase, implement
                        new features, and support the technical stack of the
                        site. Interested in becoming a developer for CULPA?{' '}
                        <a href="https://www.specpublishing.com/4-apply-now">
                            Join the Engineering team at Spectator
                        </a>
                        . Applications open at the beginning of each semester.
                    </p>

                    <h3>Moderator</h3>
                    <p style={{ fontSize: '1.2em', fontWeight: '400' }}>
                        CULPA moderators read, review, and publish student
                        reviews on a weekly basis in accordance with our
                        moderation policies. CULPA operates an independent team
                        with its own application process to conduct moderation.
                        We are currently not looking for new moderators. If you
                        are interested in helping us moderate incoming reviews,
                        check back early Spring for information on how to apply!
                    </p>
                </ContentWrapper>
            </FlexContainer>
            <Footer />
        </>
    );
};

export default JoinTheTeamPage;

import React from 'react';
import { fetchPendingReviews } from '../../../util/FetchReviewData';
import PendingReviewCard from '../../cards/review-cards/PendingReviewCard';
import GenericPanel from '../GenericPanel';
import { useAdminPanel } from '../useAdminPanel';

const AdminReviewPanel = ({
    numberOfPendingReviews,
    handleUnauthorized,
    onReviewRemoved,
}) => {
    // Use our custom hook for data fetching and infinite scrolling
    const {
        loading,
        data: pendingReviews,
        hasMoreItems,
        loadMore,
        removeItem,
    } = useAdminPanel(
        fetchPendingReviews,
        numberOfPendingReviews,
        handleUnauthorized,
    );

    // Handle review removal
    const handleRemoveReview = (reviewId) => {
        removeItem(reviewId, 'review_id');

        // Notify parent component to update the count
        if (onReviewRemoved) {
            onReviewRemoved();
        }
    };

    // Render each review card
    const renderReview = (review) => (
        <PendingReviewCard
            key={review.review_id}
            review={review}
            onRemove={handleRemoveReview}
        />
    );

    return (
        <GenericPanel
            loading={loading}
            data={pendingReviews}
            hasMoreItems={hasMoreItems}
            onLoadMore={loadMore}
            renderItem={renderReview}
            className="pending-reviews-container"
        />
    );
};

export default AdminReviewPanel;

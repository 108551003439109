import React from 'react';
import { Icon } from 'semantic-ui-react';

const CourseSyllabi = ({ syllabi }) => {
    return (
        <>
            {syllabi.map((syllabus) => {
                const date = new Date(syllabus.submission_date);
                const formattedDate = date.toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                });
                return (
                    <div>
                        <a
                            href={syllabus.read_url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {`${syllabus.professor_header.first_name} ${syllabus.professor_header.last_name} [${formattedDate}]`}
                            <Icon name="external alternate" />
                        </a>
                    </div>
                );
            })}
        </>
    );
};

export default CourseSyllabi;

import React, { useCallback } from 'react';
import { fetchPendingProfessors } from '../../../util/FetchProfessorData';
import PendingProfessorCard from '../../cards/professor-cards/PendingProfessorCard';
import GenericPanel from '../GenericPanel';
import { useAdminPanel } from '../useAdminPanel';

const PendingProfessorsPanel = ({
    numberOfPendingProfessors,
    handleUnauthorized,
    onProfessorStatusUpdated,
}) => {
    // Use our custom hook for data fetching and infinite scrolling
    const {
        loading,
        data: pendingProfessors,
        hasMoreItems,
        loadMore,
        removeItem,
    } = useAdminPanel(
        fetchPendingProfessors,
        numberOfPendingProfessors,
        handleUnauthorized,
    );

    // Handle professor removal from UI
    const handleRemoveProfessor = useCallback(
        (professorId) => {
            removeItem(professorId, 'professor_id');

            // Notify parent component to update the count
            if (onProfessorStatusUpdated) {
                onProfessorStatusUpdated();
            }
        },
        [removeItem, onProfessorStatusUpdated],
    );

    // Render each professor card
    const renderProfessor = (professor) => (
        <PendingProfessorCard
            key={professor.professor_id}
            professor={professor}
            onRemove={handleRemoveProfessor}
        />
    );

    return (
        <GenericPanel
            loading={loading}
            data={pendingProfessors}
            hasMoreItems={hasMoreItems}
            onLoadMore={loadMore}
            renderItem={renderProfessor}
            className="pending-professors-container"
        />
    );
};

export default PendingProfessorsPanel;

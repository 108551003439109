import { fetchWithRateLimitCheck } from './api';

const api_url = process.env.REACT_APP_GATEWAY_URL;

// INSERT METHODS
export function postNewVote(review_id, type) {
    return fetchWithRateLimitCheck(`${api_url}api/vote`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ review_id: review_id, type: type }),
    });
}

export function updateVote(review_id, prevType, type) {
    return fetchWithRateLimitCheck(`${api_url}api/vote`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            review_id: review_id,
            type: type,
            previous_type: prevType,
        }),
    });
}

export function deleteVote(review_id, type) {
    return fetchWithRateLimitCheck(`${api_url}api/vote`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ review_id: review_id, type: type }),
    });
}

export function fetchTotalNumberOfVotes() {
    return fetchWithRateLimitCheck(`${api_url}api/vote/total`, {
        method: 'GET',
        credentials: 'include',
    });
}

import React from 'react';
import { Dimmer, Image, Loader, Segment } from 'semantic-ui-react';

const LoaderAnimation = () => (
    <div>
        <Segment>
            <Dimmer active inverted>
                <Loader content="Loading" />
            </Dimmer>
            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
        </Segment>
    </div>
);

export default LoaderAnimation;

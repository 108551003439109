import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardContent, CardHeader, Form } from 'semantic-ui-react';
import { updateProfessorUni } from '../../../util/FetchProfessorData';
import ApproveOrRejectFooter from '../../admin/ApproveOrRejectFooter';
import ProfessorTitle from '../../common/titles/ProfessorTitle';
import { useCsrfToken } from '../../../util/CsrfTokenProvider';

const ProfessorWithMissingUniCard = ({ professor, onRemove }) => {
    const [uni, setUni] = useState('');
    const [loading, setLoading] = useState(false);
    const [uniError, setUniError] = useState(false);
    const [isSliding, setIsSliding] = useState(false);
    const [cardHeight, setCardHeight] = useState('auto');
    const cardRef = useRef(null);
    const { csrfToken } = useCsrfToken();

    // Measure the card height on mount
    useEffect(() => {
        if (cardRef.current) {
            setCardHeight(`${cardRef.current.offsetHeight}px`);
        }
    }, []);

    function handleChange(event) {
        setUni(event.target.value);
        // Clear error when user starts typing again
        if (uniError) {
            setUniError(false);
        }
    }

    async function handleSubmit() {
        if (!uni.trim()) {
            setUniError(true);
            toast.error('Please enter a UNI');
            return;
        }

        setLoading(true);
        setUniError(false);

        try {
            const response = await updateProfessorUni(
                professor.professor_id,
                uni,
                csrfToken,
            );

            if (!response.ok) {
                if (response.status === 400) {
                    setUniError(true);
                    throw new Error('Invalid UNI format');
                }
                throw new Error('Failed to update professor UNI');
            }

            toast.success('Added professor UNI successfully');

            // First set explicit height
            if (cardRef.current) {
                setCardHeight(`${cardRef.current.offsetHeight}px`);
                // Force reflow
                void cardRef.current.offsetHeight;
            }

            // Then start slide animation
            setIsSliding(true);

            // Wait for animation to complete before notifying parent to remove
            setTimeout(() => {
                onRemove(professor.professor_id);
            }, 1000); // Match this with CSS animation duration
        } catch (error) {
            toast.error(error.message || 'Failed to update professor UNI');
        } finally {
            setLoading(false);
        }
    }

    const slideOutStyle = {
        transition:
            'transform 1s ease-out, opacity 1s ease-out, height 1s ease-out, margin 1s ease-out, padding 1s ease-out',
        transform: isSliding ? 'translateX(100%)' : 'translateX(0)',
        opacity: isSliding ? 0 : 1,
        height: isSliding ? '0px' : cardHeight,
        overflow: 'hidden',
        marginTop: isSliding ? '0' : '0em',
        marginBottom: isSliding ? '0' : '1em',
        paddingTop: isSliding ? '0' : undefined,
        paddingBottom: isSliding ? '0' : undefined,
    };

    return (
        <div ref={cardRef}>
            <Card
                fluid
                style={{
                    ...slideOutStyle,
                    border: '1px solid #d4d4d5',
                    boxShadow: 'none',
                }}
            >
                <CardContent>
                    <CardHeader>
                        <ProfessorTitle
                            professor={professor}
                            includeBadge={true}
                        />
                    </CardHeader>
                    <CardContent>{professor.num_reviews} reviews</CardContent>
                    <br />
                    <Form size="large">
                        <Form.Input
                            inline="true"
                            icon="id card"
                            iconPosition="left"
                            placeholder="UNI"
                            value={uni}
                            onChange={handleChange}
                            error={
                                uniError && {
                                    content: 'Please enter a valid UNI',
                                    pointing: 'left',
                                }
                            }
                            loading={loading}
                        />
                    </Form>
                </CardContent>
                <ApproveOrRejectFooter handleApprove={handleSubmit} />
            </Card>
        </div>
    );
};

export default ProfessorWithMissingUniCard;

import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardContent, CardHeader, Icon } from 'semantic-ui-react';
import { approveSyllabus } from '../../../util/FetchSyllabusData';
import ApproveOrRejectFooter from '../../admin/ApproveOrRejectFooter';
import CourseTitle from '../../common/titles/CourseTitle';
import ProfessorTitle from '../../common/titles/ProfessorTitle';
import { useCsrfToken } from '../../../util/CsrfTokenProvider';

const PendingSyllabusCard = ({ syllabus, onRemove }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSliding, setIsSliding] = useState(false);
    const [cardHeight, setCardHeight] = useState('auto');
    const cardRef = useRef(null);
    const { csrfToken } = useCsrfToken();

    // Measure the card height on mount
    useEffect(() => {
        if (cardRef.current) {
            setCardHeight(`${cardRef.current.offsetHeight}px`);
        }
    }, []);

    const handleSuccessAction = async (action, actionName) => {
        if (isLoading) return;

        setIsLoading(true);

        try {
            const response = await approveSyllabus(
                syllabus.syllabus_id,
                action,
                csrfToken,
            );
            if (!response.ok) {
                throw new Error(response.statusText);
            }

            toast.success(`Syllabus ${actionName}`);

            // First set explicit height
            if (cardRef.current) {
                setCardHeight(`${cardRef.current.offsetHeight}px`);
                // Force reflow
                void cardRef.current.offsetHeight;
            }

            // Then start slide animation
            setIsSliding(true);

            // Wait for animation to complete before notifying parent to remove
            setTimeout(() => {
                onRemove(syllabus.syllabus_id);
            }, 1000); // Use 1s duration for consistency with other cards
        } catch (error) {
            toast.error(`Failed to ${actionName} syllabus`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleApprove = () => handleSuccessAction('approved', 'approved');
    const handleReject = () => handleSuccessAction('rejected', 'rejected');

    const slideOutStyle = {
        transition:
            'transform 1s ease-out, opacity 1s ease-out, height 1s ease-out, margin 1s ease-out, padding 1s ease-out',
        transform: isSliding ? 'translateX(100%)' : 'translateX(0)',
        opacity: isSliding ? 0 : 1,
        height: isSliding ? '0px' : cardHeight,
        overflow: 'hidden',
        marginTop: isSliding ? '0' : '0em',
        marginBottom: isSliding ? '0' : '1em',
        paddingTop: isSliding ? '0' : undefined,
        paddingBottom: isSliding ? '0' : undefined,
    };

    return (
        <div ref={cardRef}>
            <Card
                fluid
                style={{
                    ...slideOutStyle,
                    border: '1px solid #d4d4d5',
                    boxShadow: 'none',
                }}
            >
                <CardContent>
                    <CardHeader>
                        <ProfessorTitle
                            professor={syllabus.professor_header}
                            includeBadge={false}
                            includeUni={true}
                        />
                    </CardHeader>
                    <br />
                    <CardHeader>
                        <CourseTitle course={syllabus.course_header} />
                    </CardHeader>
                    <br />
                    <a
                        href={syllabus.read_url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Syllabus <Icon name="external alternate" />
                    </a>
                </CardContent>
                <ApproveOrRejectFooter
                    handleApprove={handleApprove}
                    handleReject={handleReject}
                    isLoading={isLoading}
                />
            </Card>
        </div>
    );
};

export default PendingSyllabusCard;

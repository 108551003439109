import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Button,
    Form,
    FormField,
    FormGroup,
    FormRadio,
    Label,
    Search,
    Segment,
    TextArea,
} from 'semantic-ui-react';
import styled from 'styled-components';
import Footer from '../components/common/Footer';
import Navbar from '../components/common/Navbar';
import NewCourseForm from '../components/forms/NewCourseForm'; // Will be updated below
import NewProfessorForm from '../components/forms/NewProfessorForm'; // Will be updated below
import NewCourseRow from '../components/tables/table-rows/NewCourseRow';
import NewProfessorRow from '../components/tables/table-rows/NewProfessorRow';
import ProfessorAndCourseLookupRow from '../components/tables/table-rows/ProfessorAndCourseLookupRow';
import { fetchCourseSearch } from '../util/FetchCourseData';
import { fetchProfessorSearch } from '../util/FetchProfessorData';
import { postNewPendingReview } from '../util/FetchReviewData';

const ReviewContainer = styled.div`
    margin: 2rem auto;
    width: 50%;

    @media only screen and (max-width: 768px) {
        width: 90%;
        margin: 1rem auto;
    }
`;

// Helper component to render error labels
const FieldErrorLabel = ({ messages }) => {
    if (!messages || messages.length === 0) {
        return null;
    }
    // Display first message, or join multiple if needed
    const messageText = messages.join('; ');
    return (
        <Label
            basic
            color="red"
            pointing
            className="field-error"
            style={{ marginTop: '0.25em' }}
        >
            {messageText}
        </Label>
    );
};

const NewReviewPage = () => {
    // --- State (Existing) ---
    const location = useLocation();
    const professorData = location.state?.professorData;
    const courseData = location.state?.courseData;
    const [professorSearchInput, setProfessorSearchInput] = useState('');
    const [professorSearchLoading, setProfessorSearchLoading] = useState(false);
    const [professorSearchResults, setProfessorSearchResults] = useState([
        { search_result_type: 'New Professor' },
    ]);
    const [isNewProfessor, setIsNewProfessor] = useState(false);
    const [newProfessor, setNewProfessor] = useState({}); // Will hold { first_name, last_name, uni }
    const [professorSelected, setProfessorSelected] = useState(null); // Will hold professor_id (int)
    const professorAbortControllerRef = useRef(null);
    const [courseSearchInput, setCourseSearchInput] = useState('');
    const [courseSearchLoading, setCourseSearchLoading] = useState(false);
    const [courseSearchResults, setCourseSearchResults] = useState([
        { search_result_type: 'New Course' },
    ]);
    const [isNewCourse, setIsNewCourse] = useState(false);
    const [newCourse, setNewCourse] = useState({}); // Will hold { course_name, course_code }
    const [courseSelected, setCourseSelected] = useState(null); // Will hold course_id (int)
    const courseAbortControllerRef = useRef(null);
    const [workload, setWorkload] = useState('');
    const [content, setContent] = useState('');
    const [rating, setRating] = useState(null); // Will hold rating (int 1-5)

    // --- Error state: Changed from array to object ---
    const [fieldErrors, setFieldErrors] = useState({}); // Stores { fieldName: ['error msg 1', ...] }

    // --- Effects ---
    useEffect(() => {
        if (professorData) {
            setProfessorSearchInput(
                `${professorData.first_name} ${professorData.last_name}`,
            );
            setProfessorSelected(professorData.professor_id);
            setIsNewProfessor(false);
            setFieldErrors((prev) => {
                const next = { ...prev };
                delete next.new_professor_request; // Clear potential top-level error
                // Also clear nested errors if any existed
                Object.keys(next).forEach((key) => {
                    if (key.startsWith('new_professor_request.')) {
                        delete next[key];
                    }
                });
                return next;
            });
        }
    }, [professorData]);

    useEffect(() => {
        if (courseData) {
            setCourseSearchInput(
                `[${courseData.course_code}] ${courseData.course_name}`,
            );
            setCourseSelected(courseData.course_id);
            setIsNewCourse(false);
            setFieldErrors((prev) => {
                const next = { ...prev };
                delete next.new_course_request; // Clear potential top-level error
                Object.keys(next).forEach((key) => {
                    if (key.startsWith('new_course_request.')) {
                        delete next[key];
                    }
                });
                return next;
            });
        }
    }, [courseData]);

    // Debounce for professor search
    useEffect(() => {
        // Don't trigger search immediately if input matches selected professor
        if (
            professorSelected &&
            professorData &&
            professorSearchInput ===
                `${professorData.first_name} ${professorData.last_name}`
        ) {
            setProfessorSearchLoading(false);
            return;
        }

        setProfessorSearchLoading(!!professorSearchInput.trim());

        const debounceTimer = setTimeout(() => {
            if (professorSearchInput.trim()) {
                getProfessorSearchData(professorSearchInput);
            } else {
                setProfessorSearchResults([
                    { search_result_type: 'New Professor' },
                ]);
                setProfessorSearchLoading(false);
                // Maybe clear selection if input is cleared?
                // setProfessorSelected(null);
                // setIsNewProfessor(false);
            }
        }, 500); // Adjusted debounce delay

        return () => {
            clearTimeout(debounceTimer);
            if (professorAbortControllerRef.current) {
                professorAbortControllerRef.current.abort();
            }
        };
    }, [professorSearchInput, professorSelected, professorData]); // Added dependencies

    // Debounce for course search
    useEffect(() => {
        // Don't trigger search immediately if input matches selected course
        if (
            courseSelected &&
            courseData &&
            courseSearchInput ===
                `[${courseData.course_code}] ${courseData.course_name}`
        ) {
            setCourseSearchLoading(false);
            return;
        }

        setCourseSearchLoading(!!courseSearchInput.trim());

        const debounceTimer = setTimeout(() => {
            if (courseSearchInput.trim()) {
                getCourseSearchData(courseSearchInput);
            } else {
                setCourseSearchResults([{ search_result_type: 'New Course' }]);
                setCourseSearchLoading(false);
                // Maybe clear selection if input is cleared?
                // setCourseSelected(null);
                // setIsNewCourse(false);
            }
        }, 500); // Adjusted debounce delay

        return () => {
            clearTimeout(debounceTimer);
            if (courseAbortControllerRef.current) {
                courseAbortControllerRef.current.abort();
            }
        };
    }, [courseSearchInput, courseSelected, courseData]); // Added dependencies

    // Function to check if a field (or its nested fields) has errors
    const hasError = (fieldName) => {
        const prefixDot = fieldName + '.';
        // Check direct error OR any nested error starting with prefix + '.'
        return (
            !!fieldErrors[fieldName] ||
            Object.keys(fieldErrors).some((key) => key.startsWith(prefixDot))
        );
    };

    // Function to clear errors for a field and its nested fields
    const clearFieldErrors = (fieldName) => {
        setFieldErrors((prev) => {
            const next = { ...prev };
            delete next[fieldName]; // Clear top-level error
            const prefixDot = fieldName + '.';
            Object.keys(next).forEach((key) => {
                if (key.startsWith(prefixDot)) {
                    delete next[key];
                }
            });
            return next;
        });
    };

    function resetForm() {
        // Reset professor fields
        setProfessorSearchInput('');
        setProfessorSearchResults([{ search_result_type: 'New Professor' }]);
        setIsNewProfessor(false);
        setNewProfessor({});
        setProfessorSelected(null);

        // Reset course fields
        setCourseSearchInput('');
        setCourseSearchResults([{ search_result_type: 'New Course' }]);
        setIsNewCourse(false);
        setNewCourse({});
        setCourseSelected(null);

        // Reset review fields
        setWorkload('');
        setContent('');
        setRating(null);

        // Reset errors
        setFieldErrors({});
    }

    function buildNewPendingReviewRequest() {
        var request = {};

        // Professor: send object if new, id (int) if existing, null/undefined if neither
        if (isNewProfessor) {
            // Ensure all required fields for NewProfessorRequest are present, even if empty strings
            request['new_professor_request'] = {
                first_name: newProfessor.first_name || '',
                last_name: newProfessor.last_name || '',
                uni: newProfessor.uni || '',
            };
        } else if (professorSelected !== null) {
            request['new_professor_request'] = professorSelected;
        } else {
            request['new_professor_request'] = null; // Explicitly null if nothing selected/new
        }

        // Course: send object if new, id (int) if existing, null/undefined if neither
        if (isNewCourse) {
            // Ensure all required fields for NewCourseRequest are present
            request['new_course_request'] = {
                course_name: newCourse.course_name || '',
                course_code: newCourse.course_code || '',
            };
        } else if (courseSelected !== null) {
            request['new_course_request'] = courseSelected;
        } else {
            request['new_course_request'] = null; // Explicitly null
        }

        /* review fields */
        request['content'] = content;
        request['workload'] = workload;
        request['rating'] = rating; // Send null if not selected, backend validation (ge=0) should catch it
        request['submission_date'] = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

        return request;
    }

    async function handleSubmit() {
        setFieldErrors({}); // Clear previous errors
        try {
            const requestData = buildNewPendingReviewRequest();
            const response = await postNewPendingReview(requestData);
            const result = await response.json(); // Parse JSON regardless of status

            if (response.ok && response.status === 201) {
                toast.success(
                    result.message || 'Your review was submitted successfully!',
                ); // Use message from success response if available
                resetForm();
            } else if (response.status === 400) {
                // Check if the response matches the generic ErrorResponse structure
                if (
                    result.message &&
                    result.details &&
                    result.details.validation_errors
                ) {
                    setFieldErrors(result.details.validation_errors);
                    toast.error(
                        result.message ||
                            'Please fix the errors in the form and try again.',
                    );
                } else {
                    // Handle cases where 400 is returned without a recognized validation structure
                    console.error('Unexpected 400 error structure:', result);
                    toast.error(
                        result.message ||
                            result.error ||
                            'An unexpected validation error occurred.',
                    );
                }
            } else {
                // Handle other error statuses (500, 403, etc.) using the different error responses defined
                console.error(
                    'Error submitting review:',
                    response.status,
                    result,
                );
                const errorMessage =
                    result.error ||
                    result.message ||
                    `An error occurred (Status: ${response.status})`;
                toast.error(`${errorMessage}. Please try again.`);
            }
        } catch (error) {
            console.error('Submission failed:', error);
            if (error.name !== 'AbortError') {
                toast.error(
                    'Could not connect to the server or an unexpected error occurred. Please check your network connection and try again later.',
                );
            }
        }
    }

    // --- Search Handlers ---
    function handleProfessorSearchInputChange(event) {
        const query = event.target.value;
        setProfessorSearchInput(query);
        // Clear selection and 'new' state when user types in search
        setProfessorSelected(null);
        setIsNewProfessor(false);
        setNewProfessor({}); // Clear data for the new professor form
        // Clear errors specifically related to professor selection/validation
        clearFieldErrors('new_professor_request');
    }

    function handleCourseSearchInputChange(event) {
        const query = event.target.value;
        setCourseSearchInput(query);
        // Clear selection and 'new' state
        setCourseSelected(null);
        setIsNewCourse(false);
        setNewCourse({}); // Clear data for the new course form
        // Clear errors specifically related to course selection/validation
        clearFieldErrors('new_course_request');
    }

    // --- Search Data Fetching (Unchanged but add finally block) ---
    async function getProfessorSearchData(query) {
        setProfessorSearchLoading(true); // Set loading true immediately
        try {
            if (professorAbortControllerRef.current) {
                professorAbortControllerRef.current.abort();
            }
            const abortController = new AbortController();
            professorAbortControllerRef.current = abortController;
            const response = await fetchProfessorSearch(
                query,
                abortController.signal,
            );
            if (!abortController.signal.aborted) {
                const result = await response.json();
                // Ensure 'New Professor' option is always present
                const resultsWithNew = [
                    { search_result_type: 'New Professor' },
                    ...result,
                ];
                setProfessorSearchResults(resultsWithNew);
            }
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.error('Professor search error:', error);
                setProfessorSearchResults([
                    { search_result_type: 'New Professor' },
                ]); // Reset on error
                toast.error('Failed to fetch professor search results.');
            }
        } finally {
            // Ensure loading is turned off unless aborted very quickly
            if (!professorAbortControllerRef.current?.signal.aborted) {
                setProfessorSearchLoading(false);
            }
        }
    }

    async function getCourseSearchData(query) {
        setCourseSearchLoading(true); // Set loading true immediately
        try {
            if (courseAbortControllerRef.current) {
                courseAbortControllerRef.current.abort();
            }
            const abortController = new AbortController();
            courseAbortControllerRef.current = abortController;
            const response = await fetchCourseSearch(
                query,
                abortController.signal,
            );
            if (!abortController.signal.aborted) {
                const result = await response.json();
                // Ensure 'New Course' option is always present
                const resultsWithNew = [
                    { search_result_type: 'New Course' },
                    ...result,
                ];
                setCourseSearchResults(resultsWithNew);
            }
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.error('Course search error:', error);
                setCourseSearchResults([{ search_result_type: 'New Course' }]); // Reset on error
                toast.error('Failed to fetch course search results.');
            }
        } finally {
            if (!courseAbortControllerRef.current?.signal.aborted) {
                setCourseSearchLoading(false);
            }
        }
    }

    // --- Row Renderers (Updated to clear specific errors on selection) ---
    function rowRenderer(searchResult) {
        if (searchResult.search_result_type === 'New Professor') {
            return (
                <NewProfessorRow
                    // When 'New Professor' row is clicked
                    setInput={setProfessorSearchInput} // Maybe clear input or set to "Adding New..."?
                    setIsNewProfessor={(val) => {
                        setIsNewProfessor(val);
                        if (val) {
                            setProfessorSelected(null); // Ensure no existing selection
                            setProfessorSearchInput(''); // Clear search input when adding new
                        }
                        // Clear errors when explicitly choosing 'new' or switching away
                        clearFieldErrors('new_professor_request');
                    }}
                    setNewProfessor={setNewProfessor}
                />
            );
        }

        // When an existing professor row is clicked
        return (
            <ProfessorAndCourseLookupRow
                content={searchResult}
                type={searchResult.search_result_type}
                setInput={setProfessorSearchInput} // Sets input field to selected prof name
                setIsNew={(val) => {
                    // Should always be false here
                    setIsNewProfessor(val);
                    if (!val) setNewProfessor({}); // Clear new prof data
                }}
                setSelected={(profId) => {
                    // Receives professor_id
                    setProfessorSelected(profId);
                    setIsNewProfessor(false); // Ensure not in 'new' mode
                    // Clear errors when selecting an existing one
                    clearFieldErrors('new_professor_request');
                }}
            />
        );
    }

    function courseRowRenderer(searchResult) {
        if (searchResult.search_result_type === 'New Course') {
            return (
                <NewCourseRow
                    // When 'New Course' row is clicked
                    setInput={setCourseSearchInput}
                    setIsNewCourse={(val) => {
                        setIsNewCourse(val);
                        if (val) {
                            setCourseSelected(null); // Ensure no existing selection
                            setCourseSearchInput(''); // Clear search input
                        }
                        clearFieldErrors('new_course_request');
                    }}
                    setNewCourse={setNewCourse}
                />
            );
        }

        // When an existing course row is clicked
        return (
            <ProfessorAndCourseLookupRow
                content={searchResult}
                type={searchResult.search_result_type}
                setInput={setCourseSearchInput}
                setIsNew={(val) => {
                    // Should be false
                    setIsNewCourse(val);
                    if (!val) setNewCourse({});
                }}
                setSelected={(courseId) => {
                    // Receives course_id
                    setCourseSelected(courseId);
                    setIsNewCourse(false);
                    clearFieldErrors('new_course_request');
                }}
            />
        );
    }

    // --- Render ---
    return (
        <>
            <Navbar />
            <br />
            <ReviewContainer>
                {/* Professor Section */}
                <Segment secondary>
                    <Form size="large">
                        {/* Use hasError helper for the field */}
                        <Form.Field error={hasError('NewProfessorRequest')}>
                            <strong
                                style={{
                                    marginBottom: '0.2em',
                                    display: 'inline-block',
                                }}
                            >
                                Professor
                            </strong>
                            <Search
                                style={{
                                    marginBottom: isNewProfessor ? '0' : '1em',
                                }} // Reduce margin if form shown
                                fluid
                                input={{ fluid: true }}
                                value={professorSearchInput}
                                placeholder="Search Professor Name or UNI"
                                results={professorSearchResults}
                                onSearchChange={
                                    handleProfessorSearchInputChange
                                }
                                loading={professorSearchLoading}
                                resultRenderer={rowRenderer}
                                minCharacters={1} // Start searching after 1 char
                                // Keep results open until selection or blur?
                                // open={professorSearchLoading || (professorSearchInput.length > 0 && professorSearchResults.length > 0)}
                                // onResultSelect={(_, { result }) => { /* Handled by rowRenderers */ }}
                            />
                            {/* Display top-level errors for professor selection */}
                            {!isNewProfessor && (
                                <FieldErrorLabel
                                    messages={fieldErrors.NewProfessorRequest}
                                />
                            )}
                        </Form.Field>
                    </Form>
                    {/* Show NewProfessorForm only when isNewProfessor is true */}
                    {isNewProfessor && (
                        <NewProfessorForm
                            setNewProfessor={setNewProfessor} // Pass setter
                            // Pass only the errors relevant to this sub-form
                            fieldErrors={fieldErrors}
                            clearFieldErrors={clearFieldErrors} // Pass clearer function
                        />
                    )}
                </Segment>

                {/* Course Section */}
                <Segment tertiary>
                    <Form size="large">
                        {/* Use hasError helper for the field */}
                        <Form.Field error={hasError('NewCourseRequest')}>
                            <strong
                                style={{
                                    marginBottom: '0.2em',
                                    display: 'inline-block',
                                }}
                            >
                                Course
                            </strong>
                            <Search
                                style={{
                                    marginBottom: isNewCourse ? '0' : '1em',
                                }}
                                fluid
                                input={{ fluid: true }}
                                value={courseSearchInput}
                                placeholder="Search Course Code or Name"
                                results={courseSearchResults}
                                onSearchChange={handleCourseSearchInputChange}
                                loading={courseSearchLoading}
                                resultRenderer={courseRowRenderer}
                                minCharacters={1}
                            />
                            {/* Display top-level errors for course selection */}
                            {!isNewCourse && (
                                <FieldErrorLabel
                                    messages={fieldErrors.NewCourseRequest}
                                />
                            )}
                        </Form.Field>
                        {/* Show NewCourseForm only when isNewCourse is true */}
                        {isNewCourse && (
                            <NewCourseForm
                                setNewCourse={setNewCourse} // Pass setter
                                // Pass down only the errors relevant to this sub-form
                                fieldErrors={fieldErrors}
                                clearFieldErrors={clearFieldErrors} // Pass clearer function
                            />
                        )}
                    </Form>
                </Segment>

                {/* Review Content Section */}
                <Form size="big">
                    {/* Content Field */}
                    <Form.Field error={!!fieldErrors.content}>
                        {/* Use label prop directly on FormField */}
                        <label
                            htmlFor="review-content"
                            style={{
                                fontWeight: 'bold',
                                display: 'block',
                                marginBottom: '0.2em',
                            }}
                        >
                            Content
                        </label>
                        <TextArea
                            id="review-content" // Match label htmlFor
                            placeholder={`What were your expectations going into this class?\nWhat do you think of your professor's teaching style?\nWhat do you think of the content of the course?`}
                            value={content}
                            onChange={(e) => {
                                setContent(e.target.value);
                                // Clear error for 'content' when user types
                                clearFieldErrors('content');
                            }}
                            style={{ minHeight: '10em' }} // Use minHeight for TextArea
                        />
                        <FieldErrorLabel messages={fieldErrors.content} />
                    </Form.Field>

                    {/* Workload Field */}
                    <Form.Field error={!!fieldErrors.workload}>
                        <label
                            htmlFor="review-workload"
                            style={{
                                fontWeight: 'bold',
                                display: 'block',
                                marginBottom: '0.2em',
                            }}
                        >
                            Workload Review
                        </label>
                        <TextArea
                            id="review-workload"
                            placeholder={`What assignments were set and how were they weighted?\nWhat do you think of your professor's grading?\nOn average, how many hours per week did you spend on assignments for this class?`}
                            value={workload}
                            onChange={(e) => {
                                setWorkload(e.target.value);
                                clearFieldErrors('workload');
                            }}
                            style={{ minHeight: '11em' }}
                        />
                        <FieldErrorLabel messages={fieldErrors.workload} />
                    </Form.Field>

                    {/* Rating Field */}
                    <Form.Field error={!!fieldErrors.rating}>
                        <strong
                            style={{ display: 'block', marginBottom: '0.5em' }}
                        >
                            Rating
                        </strong>
                        {/* Use FormGroup for radio buttons, remove inline */}
                        <FormGroup
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '0.5em',
                            }}
                        >
                            {[
                                {
                                    value: 1,
                                    label: 'One of the worst experiences at Columbia. Avoid at all costs',
                                },
                                {
                                    value: 2,
                                    label: 'Strong negative experience. Take only if necessary',
                                },
                                {
                                    value: 3,
                                    label: 'Both negatives and positives, much like life itself',
                                },
                                {
                                    value: 4,
                                    label: 'Strong positive experience. Take if possible',
                                },
                                {
                                    value: 5,
                                    label: 'A Columbia gem. Life-changing moments await',
                                },
                            ].map((r) => (
                                <FormRadio
                                    key={r.value}
                                    label={r.label}
                                    value={String(r.value)} // Value is usually string
                                    checked={rating === r.value}
                                    onChange={() => {
                                        setRating(r.value);
                                        clearFieldErrors('rating');
                                    }}
                                    // Apply error styling indirectly via Form.Field
                                />
                            ))}
                        </FormGroup>
                        <FieldErrorLabel messages={fieldErrors.rating} />
                    </Form.Field>

                    {/* Submit Button */}
                    <FormField
                        control={Button}
                        onClick={handleSubmit}
                        primary // Make button stand out
                        size="large"
                        style={{ marginTop: '1em' }}
                    >
                        Submit Review
                    </FormField>
                </Form>
            </ReviewContainer>
            <br />
            <Footer />
        </>
    );
};

export default NewReviewPage;

import React from 'react';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    Grid,
} from 'semantic-ui-react';
import styled from 'styled-components';
import CourseTitle from '../../common/titles/CourseTitle';
import ProfessorTitle from '../../common/titles/ProfessorTitle';
import ReviewFooter from './ReviewFooter';

const StyledCardDescription = styled(CardDescription)`
    overflow: hidden;
    text-overflow: ellipsis;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`;

const ReviewCard = ({
    review,
    applyDescriptionStyling = true,
    showProfessor = false,
    showCourse = false,
}) => {
    const date = new Date(review.submission_date);
    const formattedDate = date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    });

    const DescriptionComponent = applyDescriptionStyling
        ? StyledCardDescription
        : CardDescription;

    return (
        <Card
            fluid={true}
            className="review-card-padding"
            style={{
                borderRadius: '5px',
                backgroundColor: '#F2F2F2',
                fontWeight: '500',
                color: 'black',
                boxShadow: '0 0 0 0',
                textAlign: 'left',
                paddingLeft: '1em',
                paddingBottom: '1em',
                overflow: 'hidden',
                marginLeft: '0',
                marginRight: '0',
            }}
            className="w-100"
        >
            <Grid stackable divided className="margin-0">
                <Grid.Column
                    computer={14}
                    tablet={16}
                    mobile={16}
                    className="padding-card"
                >
                    <CardContent>
                        {showProfessor && (
                            <CardHeader
                                style={{ fontSize: '18px', fontWeight: '700' }}
                            >
                                <ProfessorTitle
                                    professor={review.professor_header}
                                />
                            </CardHeader>
                        )}
                        {showCourse && showProfessor && <br />}
                        {showCourse && (
                            <CardHeader
                                style={{ fontSize: '18px', fontWeight: '700' }}
                            >
                                <CourseTitle course={review.course_header} />
                            </CardHeader>
                        )}
                        <br></br>
                        <CardHeader
                            style={{
                                fontSize: '16px',
                                fontWeight: '700',
                                paddingBottom: '0.5em',
                            }}
                        >
                            {formattedDate}
                        </CardHeader>
                        <DescriptionComponent>
                            {review.content}
                        </DescriptionComponent>
                        <br></br>
                        <CardHeader
                            style={{
                                fontSize: '16px',
                                fontWeight: '700',
                                paddingBottom: '0.5em',
                            }}
                        >
                            {'Workload'}
                        </CardHeader>
                        <DescriptionComponent>
                            {review.workload}
                        </DescriptionComponent>
                    </CardContent>
                </Grid.Column>
                <Grid.Column
                    computer={2}
                    tablet={16}
                    mobile={16}
                    className="culpa-lighter-blue padding-footer"
                >
                    <ReviewFooter
                        review_id={review.review_id}
                        agree_count={review.agree_count}
                        disagree_count={review.disagree_count}
                        funny_count={review.funny_count}
                    />
                </Grid.Column>
            </Grid>
        </Card>
    );
};

export default ReviewCard;

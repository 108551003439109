import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

const MenuContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    transform: ${(props) =>
        props.open ? 'translateX(0)' : 'translateX(100%)'};
    transition: transform 0.3s ease-in-out;
    padding: 2rem 1rem;
    z-index: 1000;

    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const Overlay = styled.div`
    display: ${(props) => (props.open ? 'block' : 'none')};
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 900;
`;

const ResponsiveWriteReviewButton = styled(Button)`
    @media (min-width: 769px) {
        display: none;
    }
`;

const MobileMenu = ({ open, onClose }) => {
    return (
        <>
            <Overlay open={open} onClick={onClose} />
            <MenuContainer open={open}>
                <Button content="Departments" href="" fluid />
                <Button content="Announcements" href="/announcements" fluid />
                <Button content="About" href="/about" fluid />
                <Button content="Join the Team" href="/join" fluid />
                <Button content="FAQs" href="/faqs" fluid />
                <Button content={'Admin Login'} href="/login" fluid />
                <ResponsiveWriteReviewButton
                    href="/review/new"
                    className="orange-button"
                    content="Write a Review"
                />
            </MenuContainer>
        </>
    );
};
MobileMenu.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default MobileMenu;

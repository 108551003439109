import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardContent, CardHeader, Message } from 'semantic-ui-react';
import {
    approveProfessor,
    fetchProfessorSearch,
} from '../../../util/FetchProfessorData';
import ApproveOrRejectFooter from '../../admin/ApproveOrRejectFooter';
import ProfessorTitle from '../../common/titles/ProfessorTitle';
import ProfessorsWithSimilarNamesTable from '../../tables/ProfessorsWithSimilarNamesTable';
import { useCsrfToken } from '../../../util/CsrfTokenProvider';

const PendingProfessorCard = ({ professor, onRemove }) => {
    const [similarProfessors, setSimilarProfessors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSliding, setIsSliding] = useState(false);
    const [cardHeight, setCardHeight] = useState('auto');
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const cardRef = useRef(null);
    const contentLoadedRef = useRef(false);

    const { csrfToken } = useCsrfToken();

    // Update card height when similarProfessors changes
    useEffect(() => {
        if (!isInitialLoad && cardRef.current) {
            const timeoutId = setTimeout(() => {
                setCardHeight(`${cardRef.current.offsetHeight}px`);
            }, 100);
        }
    }, [similarProfessors, isInitialLoad]);

    // Measure the card height on mount and whenever window resizes
    useEffect(() => {
        const updateCardHeight = () => {
            if (cardRef.current) {
                setCardHeight(`${cardRef.current.offsetHeight}px`);
            }
        };

        // Update height after a short delay to ensure the DOM has rendered
        const timeoutId = setTimeout(() => {
            updateCardHeight();
            setIsInitialLoad(false);
        }, 200);

        // Also add resize listener to handle window size changes
        window.addEventListener('resize', updateCardHeight);

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('resize', updateCardHeight);
        };
    }, []);

    useEffect(() => {
        const getSimilarProfessors = async () => {
            try {
                const response = await fetchProfessorSearch(
                    `${professor.first_name} ${professor.last_name}`,
                    3,
                );
                const data = await response.json();
                setSimilarProfessors(data);
                contentLoadedRef.current = true;

                // Force a height update after content is loaded
                setTimeout(() => {
                    if (cardRef.current) {
                        setCardHeight(`${cardRef.current.offsetHeight}px`);
                    }
                }, 100);
            } catch (error) {
                console.error(error);
                contentLoadedRef.current = true;
            }
        };

        getSimilarProfessors();
    }, [professor.first_name, professor.last_name]);

    const handleSuccessAction = async (action, actionName) => {
        if (isLoading) return;

        setIsLoading(true);

        try {
            const response = await approveProfessor(
                professor.professor_id,
                action,
                csrfToken,
            );

            if (response.status === 200) {
                toast.success(`Professor ${actionName} successfully`);

                // Make sure we have the correct height before starting animation
                if (cardRef.current) {
                    setCardHeight(`${cardRef.current.offsetHeight}px`);
                    // Force reflow
                    void cardRef.current.offsetHeight;
                }

                // Then start slide animation
                setIsSliding(true);

                // Wait for animation to complete before notifying parent to remove
                setTimeout(() => {
                    onRemove(professor.professor_id);
                }, 1000); // Match this with CSS animation duration
            } else {
                toast.error(`Failed to ${actionName} professor`);
            }
        } catch (error) {
            console.error(error);
            toast.error(`Failed to ${actionName} professor`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleApprove = () => handleSuccessAction('approved', 'approve');
    const handleReject = () => handleSuccessAction('rejected', 'reject');

    const slideOutStyle = {
        transition:
            'transform 1s ease-out, opacity 1s ease-out, height 1s ease-out, margin 1s ease-out, padding 1s ease-out',
        transform: isSliding ? 'translateX(100%)' : 'translateX(0)',
        opacity: isSliding ? 0 : 1,
        height: isSliding ? '0px' : cardHeight,
        overflow: 'hidden',
        marginTop: isSliding ? '0' : '0em',
        marginBottom: isSliding ? '0' : '1em',
        paddingTop: isSliding ? '0' : undefined,
        paddingBottom: isSliding ? '0' : undefined,
    };

    return (
        <div ref={cardRef}>
            <Card
                fluid
                style={{
                    ...slideOutStyle,
                    border: '1px solid #d4d4d5',
                    boxShadow: 'none',
                }}
            >
                <CardContent>
                    <CardHeader>
                        <ProfessorTitle
                            professor={professor}
                            includeBadge={false}
                            includeUni={true}
                        />
                    </CardHeader>
                    {similarProfessors.length ? (
                        <Message warning>
                            <Message.Header>Warning</Message.Header>
                            <p>
                                {`There are already professors with names similar to ${professor.first_name} ${professor.last_name}. Verify below to ensure it is not a duplicate entry:`}
                            </p>
                            {similarProfessors &&
                                similarProfessors.map((similarProfessor) => (
                                    <ProfessorTitle
                                        professor={
                                            similarProfessor.professor_header
                                        }
                                        key={similarProfessor.professor_id}
                                        includeBadge={false}
                                        includeUni={true}
                                    />
                                ))}
                        </Message>
                    ) : null}
                </CardContent>
                <ApproveOrRejectFooter
                    handleApprove={handleApprove}
                    handleReject={handleReject}
                    isLoading={isLoading}
                />
            </Card>
        </div>
    );
};

export default PendingProfessorCard;

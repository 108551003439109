import { fetchWithRateLimitCheck } from './api'; // Import our custom fetch function

const api_url = process.env.REACT_APP_GATEWAY_URL;

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export function fetchCurrentUser() {
    return fetchWithRateLimitCheck(
        `${api_url}api/authentication/login/status`,
        {
            method: 'GET',
            headers: {
                'X-CSRF-TOKEN': getCookie('csrf_access_token'),
            },
            credentials: 'include',
        },
    );
}

export function loginRequest(username, password) {
    return fetchWithRateLimitCheck(`${api_url}api/authentication/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ username: username, password: password }),
    });
}

export default getCookie;

import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardMeta,
} from 'semantic-ui-react';
import { approveReview } from '../../../util/FetchReviewData';
import ApproveOrRejectFooter from '../../admin/ApproveOrRejectFooter';
import CourseTitle from '../../common/titles/CourseTitle';
import { useCsrfToken } from '../../../util/CsrfTokenProvider';

const PendingReviewCard = ({ review, onRemove }) => {
    const [isSliding, setIsSliding] = useState(false);
    const [cardHeight, setCardHeight] = useState('auto');
    const cardRef = useRef(null);

    const { csrfToken } = useCsrfToken();

    // Measure the card height on mount
    useEffect(() => {
        if (cardRef.current) {
            setCardHeight(`${cardRef.current.offsetHeight}px`);
        }
    }, []);

    const handleSuccessAction = async (action, actionName) => {
        try {
            const response = await approveReview(
                review.review_id,
                action,
                csrfToken,
            );

            if (response.status === 200) {
                toast.success(`${actionName} successfully`);

                // First set explicit height
                if (cardRef.current) {
                    setCardHeight(`${cardRef.current.offsetHeight}px`);
                    // Force reflow
                    void cardRef.current.offsetHeight;
                }

                // Then start slide animation
                setIsSliding(true);

                // Wait for animation to complete before notifying parent to remove
                setTimeout(() => {
                    onRemove(review.review_id);
                }, 1000); // Match this with CSS animation duration
            } else {
                toast.error(`Failed to ${actionName.toLowerCase()}`);
            }
        } catch (error) {
            console.error(error);
            toast.error(`Failed to ${actionName.toLowerCase()}`);
        }
    };

    const handleApprove = () =>
        handleSuccessAction('approved', 'Review approved');
    const handleInsufficient = () =>
        handleSuccessAction('insufficient', 'Marked review as insufficient');
    const handleLibel = () =>
        handleSuccessAction('libel', 'Marked review as libel');

    const slideOutStyle = {
        transition:
            'transform 1s ease-out, opacity 1s ease-out, height 1s ease-out, margin 1s ease-out, padding 1s ease-out',
        transform: isSliding ? 'translateX(100%)' : 'translateX(0)',
        opacity: isSliding ? 0 : 1,
        height: isSliding ? '0px' : cardHeight,
        overflow: 'hidden',
        marginTop: isSliding ? '0' : '0em',
        marginBottom: isSliding ? '0' : '1em',
        paddingTop: isSliding ? '0' : undefined,
        paddingBottom: isSliding ? '0' : undefined,
    };

    return (
        <div ref={cardRef}>
            <Card
                fluid
                style={{
                    ...slideOutStyle,
                    border: '1px solid #d4d4d5',
                    boxShadow: 'none',
                }}
            >
                <CardContent>
                    <CardHeader>
                        <CourseTitle course={review.course_header} />
                    </CardHeader>

                    <br></br>

                    <CardMeta>{review.submission_date}</CardMeta>

                    <br></br>

                    <CardHeader>{'Review'}</CardHeader>

                    <CardDescription>{review.content}</CardDescription>

                    <br></br>

                    <CardHeader>{'Workload'}</CardHeader>

                    <CardDescription>{review.workload}</CardDescription>
                </CardContent>
                <ApproveOrRejectFooter
                    isReview={true}
                    handleApprove={handleApprove}
                    handleInsufficient={handleInsufficient}
                    handleLibel={handleLibel}
                />
            </Card>
        </div>
    );
};

export default PendingReviewCard;

import React from 'react';
import { CardHeader, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import LoaderAnimation from '../../common/LoaderAnimation';
import ReviewCard from './ReviewCard';

const AgreedAndDisagreedReviews = ({
    professorSpotlight,
    spotlightLoading,
}) => {
    if (spotlightLoading || !professorSpotlight) {
        return <LoaderAnimation />;
    }

    return (
        <Grid columns={2} stackable>
            <GridRow>
                <GridColumn textAlign="center">
                    <CardHeader textAlign="center" as="h2">
                        Most Agreed Review
                    </CardHeader>
                    {professorSpotlight.agreed_review && (
                        <ReviewCard
                            key={0}
                            review={professorSpotlight.agreed_review}
                            showCourse={true}
                        />
                    )}
                </GridColumn>
                <GridColumn textAlign="center">
                    <CardHeader textAlign="center" as="h2">
                        Most Disagreed Review
                    </CardHeader>
                    {professorSpotlight.controversial_review && (
                        <ReviewCard
                            key={1}
                            review={professorSpotlight.controversial_review}
                            showCourse={true}
                        />
                    )}
                </GridColumn>
            </GridRow>
        </Grid>
    );
};

export default AgreedAndDisagreedReviews;

import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import AdminLayout from '../components/admin/AdminLayout';
import AdminReviewPanel from '../components/admin/panels/AdminReviewPanel';
import MissingCourseCodePanel from '../components/admin/panels/MissingCourseCodePanel';
import PendingCoursesPanel from '../components/admin/panels/PendingCoursesPanel';
import PendingProfessorsPanel from '../components/admin/panels/PendingProfessorsPanel';
import PendingSyllabiPanel from '../components/admin/panels/PendingSyllabiPanel';
import ProfessorWithMissingUniPanel from '../components/admin/panels/ProfessorWithMissingUniPanel';
import UserManagementPanel from '../components/admin/panels/UserManagementPanel';
import Footer from '../components/common/Footer';
import Navbar from '../components/common/Navbar';

// Import the single utility function for fetching all counts
import { fetchAllCounts } from '../util/FetchAdminPageData';

const AdminPage = () => {
    const navigate = useNavigate();
    const [selectedPanel, setSelectedPanel] = useState('reviews');

    // Define panel counts in a single state object
    const [counts, setCounts] = useState({
        reviews: 0,
        professors: 0,
        missingUni: 0,
        courses: 0,
        missingCode: 0,
        syllabi: 0,
        quotes: 0, // Added for quotes if needed
    });

    // Handle unauthorized responses consistently
    const handleUnauthorized = useCallback(
        (response) => {
            if (response && response.status === 401) {
                navigate('/login');
                return true;
            }
            return false;
        },
        [navigate],
    );

    // Function to fetch all counts at once
    const refreshAllCounts = useCallback(async () => {
        try {
            const response = await fetchAllCounts();
            if (handleUnauthorized(response)) return;

            if (!response.ok) {
                console.error(`Failed to fetch counts: ${response.statusText}`);
                return;
            }

            const data = await response.json();

            // Map API response to our state structure
            setCounts({
                reviews: data.number_of_reviews || 0,
                professors: data.number_of_professors || 0,
                missingUni: data.number_of_professors_with_missing_uni || 0,
                courses: data.number_of_courses || 0,
                missingCode:
                    data.number_of_courses_with_missing_course_code || 0,
                syllabi: data.number_of_syllabi || 0,
                quotes: data.number_of_pending_quotes || 0, // Added for quotes if needed
            });
        } catch (error) {
            console.error('Error fetching counts:', error);
        }
    }, [handleUnauthorized]);

    // Fetch all counts when component mounts
    useEffect(() => {
        refreshAllCounts();
    }, [refreshAllCounts]);

    // Define menu items based on counts
    const menuItems = [
        { id: 'reviews', title: 'Pending Reviews', count: counts.reviews },
        {
            id: 'professors',
            title: 'Pending Professors',
            count: counts.professors,
        },
        {
            id: 'missingUni',
            title: 'Missing Prof. UNIs',
            count: counts.missingUni,
        },
        { id: 'courses', title: 'Pending Courses', count: counts.courses },
        {
            id: 'missingCode',
            title: 'Missing Course Codes',
            count: counts.missingCode,
        },
        { id: 'syllabi', title: 'Pending Syllabi', count: counts.syllabi },
        { id: 'users', title: 'User Management' },
    ];

    // Render the currently selected panel
    const renderPanel = () => {
        switch (selectedPanel) {
            case 'reviews':
                return (
                    <AdminReviewPanel
                        numberOfPendingReviews={counts.reviews}
                        handleUnauthorized={handleUnauthorized}
                        onReviewRemoved={refreshAllCounts}
                    />
                );
            case 'professors':
                return (
                    <PendingProfessorsPanel
                        numberOfPendingProfessors={counts.professors}
                        handleUnauthorized={handleUnauthorized}
                        onProfessorStatusUpdated={refreshAllCounts}
                    />
                );
            case 'missingUni':
                return (
                    <ProfessorWithMissingUniPanel
                        numberOfProfessorsWithMissingUni={counts.missingUni}
                        handleUnauthorized={handleUnauthorized}
                        onProfessorUniUpdated={refreshAllCounts}
                    />
                );
            case 'courses':
                return (
                    <PendingCoursesPanel
                        numberOfPendingCourses={counts.courses}
                        handleUnauthorized={handleUnauthorized}
                        onCourseStatusUpdated={refreshAllCounts}
                    />
                );
            case 'missingCode':
                return (
                    <MissingCourseCodePanel
                        numberOfCoursesWithMissingCourseCode={
                            counts.missingCode
                        }
                        handleUnauthorized={handleUnauthorized}
                        onCourseCodeUpdated={refreshAllCounts}
                    />
                );
            case 'syllabi':
                return (
                    <PendingSyllabiPanel
                        numberOfPendingSyllabi={counts.syllabi}
                        handleUnauthorized={handleUnauthorized}
                        onSyllabusStatusUpdated={refreshAllCounts}
                    />
                );
            case 'users':
                return (
                    <UserManagementPanel
                        handleUnauthorized={handleUnauthorized}
                    />
                );
            default:
                return null;
        }
    };

    // Styles
    const pageWrapperStyle = {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    };

    const contentStyle = {
        flex: '1 0 auto',
        paddingTop: '2em',
        paddingBottom: '3em',
    };

    const footerStyle = {
        flexShrink: 0,
    };

    return (
        <div style={pageWrapperStyle}>
            <Navbar />
            <Container style={contentStyle}>
                <AdminLayout
                    menuItems={menuItems}
                    selectedItem={selectedPanel}
                    onSelectItem={setSelectedPanel}
                >
                    {renderPanel()}
                </AdminLayout>
            </Container>
            <Footer style={footerStyle} />
        </div>
    );
};

export default AdminPage;
